.heading-1 {
	font-size: var(--font-size-22);
	font-weight: var(--font-weight-500);
	color: var(--primary-font-color);
}

.heading-2 {
	font-size: var(--font-size-42);
	font-weight: var(--font-weight-600);
	color: var(--primary-font-color);
}

.subtitle-1 {
	font-size: var(--font-size-16);
	font-weight: var(--font-weight-600);
	color: var(--primary-font-color);
}

.subtitle-2 {
	font-size: var(--font-size-12);
	font-weight: var(--font-weight-600);
	color: var(--grey-shade-3);
}

.subtitle-3 {
	font-size: var(--font-size-16);
	font-weight: var(--font-weight-600);
	color: var(--alternate-primary-color);
}

.table-column-text {
	font-size: var(--font-size-12);
	font-weight: var(--font-weight-600);
	color: var(--primary-font-color);
}

.table-data-text {
	font-size: var(--font-size-12);
	font-weight: var(--font-weight-normal);
	color: var(--primary-light-font-color);
}

.left-nav-text {
	font-size: var(--font-size-14);
	font-weight: var(--font-weight-normal);
	color: var(--primary-font-color);
}

.paragraph-1 {
	font-size: var(--font-size-12);
	letter-spacing: 0.05px;
	font-weight: var(--font-weight-normal);
	color: var(--primary-light-font-color);
}

.hyperlink {
	font-size: var(--font-size-14);
	font-weight: var(--font-weight-600);
	color: var(--hyperlink-font-color);
}

.label-dark {
	font-size: var(--font-size-14);
	font-weight: var(--font-weight-500);
	color: var(--label-font-color-dark);
}

.label-normal {
	font-size: var(--font-size-14);
	font-weight: var(--font-weight-500);
	color: var(--label-font-color-normal);
}
