@import 'ag-grid-community/src/styles/ag-grid.scss';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';

.ag-theme-alpine {
	@include ag-theme-alpine(); //(foreground-color: $filed-blue));

	//--ag-foreground-color: pink;
	// .ag-theme-alpine .ag-popup-editor {
	// 	background: white;
	// }

	// TEMPORARY - AG GRID
	.ag-tabs {
		//todo: decide if these changes should be kept
		margin-left: -156px;
		margin-top: 30px;
	}
	.ag-header-icon {
		opacity: 1 !important;
	}

	.ag-header-icon .ag-icon-menu {
		content: url('/assets/icons/ag-grid/ag-icon-menu.svg');
	}
	.ag-header-icon .ag-icon-menu {
		&:hover {
			content: url('/assets/icons/ag-grid/ag-icon-menu-hover.svg');
		}
	}

	.ads-manager-status-cell {
		display: flex;
		justify-content: center; /* align horizontal */
		align-items: center;
	}

	text-align: left;
	font: 14px/1px Roboto;
	letter-spacing: 0.14px;
	color: #0b0b0b;
	opacity: 1;

	span {
		margin-top: 1vh;
		margin-bottom: 0.9vh;
		line-height: 1.3;
		font-size: 14px;
	}

	.checkbox-column {
		content: url('/assets/icons/ag-grid/checkbox-icon.svg');
	}

	.status-column {
		content: url('/assets/icons/ag-grid/status-icon.svg');
	}

	.ag-icon-tick:before {
		content: url('/assets/icons/ag-grid/bullet-check.svg');
	}
	.ag-icon-tick {
		display: revert;
	}
}
