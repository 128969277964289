// ******************** RADIO BUTTONS ******************** //

// ! Custom Radio Button
.custom-radio-button {
	display: flex !important;
	align-items: center !important;
	width: 100% !important;

	--radio-dark-color: var(--alternate-primary-color);

	&.alternate-1 {
		--radio-dark-color: var(--primary-color);
	}

	.mat-radio-label {
		height: 48px !important;
		padding: 0 14px !important;
	}

	.mat-radio-label-content {
		font-weight: 500 !important;
		font-size: 14px !important;
		letter-spacing: 0px !important;
		color: var(--alternate-primary-color) !important;
		display: flex !important;
		align-items: center !important;
		gap: 8px !important;
		padding-left: 14px !important;
	}

	&.h-unset {
		.mat-radio-label {
			height: unset !important;
		}
	}

	&.mat-accent .mat-radio-outer-circle {
		border-color: var(--grey-shade-6) !important;
	}

	&.mat-accent.mat-radio-checked .mat-radio-outer-circle {
		border-color: var(--radio-dark-color) !important;
	}

	&.mat-accent .mat-radio-inner-circle {
		color: var(--radio-dark-color) !important;
		background-color: var(--radio-dark-color) !important;
	}

	&.mat-accent .mat-radio-ripple .mat-ripple-element {
		background-color: var(--radio-dark-color) !important;
	}
}

// ******************** CHECK BOXES ******************** //

// ! Custom checkbox
.custom-checkbox {
	display: flex !important;
	width: 100%;

	--check-color-text: var(--alternate-primary-color);
	--check-color-dark: var(--alternate-primary-color);
	--check-color-light: --alternate-primary-color;

	&.alternate-1 {
		--check-color-dark: var(--primary-color);
	}

	& .mat-checkbox-background,
	.mat-checkbox-frame {
		border-radius: 5px !important;
		border-color: var(--check-color-light) !important;
		border-width: 1px !important;
	}

	&.mat-checkbox-checked .mat-checkbox-checkmark {
		transform: scale(0.9) !important;
	}

	.mat-checkbox-inner-container {
		height: 19px !important;
		margin-right: 10px !important;
		width: 19px !important;
	}

	&.mat-checkbox-ripple .mat-ripple-element,
	&.mat-checkbox-checked.mat-accent .mat-checkbox-background,
	&.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
		background-color: var(--check-color-dark) !important;
	}

	&.mat-checkbox-checked.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
	}

	&.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
	}

	.mat-checkbox-layout,
	.mat-checkbox-label {
		width: 100% !important;
		font-weight: 500 !important;
		font-size: 14px !important;
		font-family: 'Gilroy' !important;
		letter-spacing: 0.14px !important;
		color: var(--check-color-text) !important;
	}

	.mat-checkbox-label {
		width: 100% !important;
		overflow: hidden !important;
		height: 40px !important;
		display: flex !important;
		align-items: center !important;
		white-space: normal !important;
	}
}

// ! Custom checkbox shorter
.custom-checkbox-shorter {
	display: flex !important;

	--checkshort-color-text: var(--alternate-primary-color);
	--checkshort-color-dark: var(--alternate-primary-color);
	--check-color-light: --alternate-primary-color;

	&.alternate-1 {
		--checkshort-color-dark: var(--primary-color);
	}

	& .mat-checkbox-background,
	.mat-checkbox-frame {
		border-radius: 5px !important;
		border-color: var(--checkshort-color-light) !important;
		border-width: 1px !important;
	}

	&.mat-checkbox-checked .mat-checkbox-checkmark {
		transform: scale(0.9) !important;
	}

	.mat-checkbox-inner-container {
		height: 19px !important;
		// margin-right: 10px !important;
		width: 19px !important;
	}

	&.mat-checkbox-ripple .mat-ripple-element,
	&.mat-checkbox-checked.mat-accent .mat-checkbox-background,
	&.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
		background-color: var(--checkshort-color-dark) !important;
	}

	&.mat-checkbox-checked.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
	}

	&.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
		pointer-events: none;
	}

	.mat-checkbox-label {
		display: none !important;
	}
}
