@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../../src/assets/styles/variable';
@import 'typography.scss';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'responsiveness';
@import 'ag-grid';
@import '../../../src/assets/styles/bootstrap-similar-classes.scss';
@import '../../../src/assets/styles/margin-padding-custom.scss';
@import '../../../src/assets/styles/width.scss';
@import '../../../src/assets/styles/grid-system.scss';

$error-red-color: #f0432c;

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.width-full {
	width: 100%;
}

.w-fit {
	width: fit-content !important;
}

.mat-tooltip {
	font-weight: 500 !important;
	font-family: 'Gilroy' !important;
	font-size: 14px !important;
	line-height: 22px !important;
	letter-spacing: 0.14px !important;
	color: #121212 !important;
	background-color: #ffffff !important;
	padding: 20px !important;
	box-shadow: 0px 3px 9px #0000001a !important;
	border: 1px solid #ebebeb !important;
	border-radius: 5px !important;
	max-width: 313px !important;
}

// CALENDLY POPUP STYLES
.calendly-overlay {
	background-color: #ffffff;

	.calendly-close-overlay {
		pointer-events: none;
		background: #ffffff;
		// backdrop-filter: blur(3px);
	}

	.calendly-popup-close {
		display: none;
	}
}

// END CALENDLY POPUP STYLES

.opacity-50 {
	opacity: 0.5;
}

.pointer-none {
	pointer-events: none;
}

.height-full {
	height: 100%;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

html body .mdi:before,
html body .mdi-set {
	line-height: initial;
}

.m-auto {
	margin: auto;
}

.camp-heading {
	font-size: 14px;
	font-weight: 500;
	font-family: 'Gilroy';
	color: #454545;
}

.camp-flow-row {
	max-width: 200px;
	min-width: 120px;
}

.camp-foot-value {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-family: 'Gilroy';
	font-size: 16px;
	font-weight: 500;
	position: relative;
	line-height: 20px;
}

.camp-mid-value {
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.24px;
	color: #121212;
}

@media screen and (max-width: 1599px) {
	.camp-mid-value {
		font-size: 20px;
	}
}

.camp-arrow {
	transform: scale(0.7);
	color: #0b0b0b;
	cursor: default;
}

.border-right-small {
	border-right: 1px solid #dddddd;
}

/*******************
Headings
*******************/

:not(.mat-calendar-body-disabled):hover
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
	background-color: rgba(0, 0, 0, 0.04);
}

:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-semi-selected,
.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-semi-selected,
.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-semi-selected {
	background-color: $filed-blue;
	color: $white;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
	border-radius: 100% 0 0 100%;
	background-color: $filed-lighter-gray;
}

.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
	border-radius: 0 100% 100% 0;
	background-color: $filed-lighter-gray;
}

.mat-calendar-body
	> tr
	.mat-calendar-cell-semi-selected
	~ .mat-calendar-cell-semi-selected {
	border-radius: 0;
}

.mat-calendar-cell-semi-selected {
	background-color: $filed-lighter-gray;
}

.mat-calendar-cell-over > .mat-calendar-body-cell-content {
	border: $filed-blue;
}

::ng-deep {
	.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
		background-color: #2585fe !important;
	}

	.mat-checkbox-checked.mat-accent .mat-checkbox-background,
	.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
		background-color: #2585fe;
	}

	.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
		background-color: #2585fe !important;
	}
}

.t-align-c {
	text-align: center;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.d-j-c-flex-end {
	justify-content: flex-end;
}

.d-j-c-flex-column {
	flex-direction: column !important;
}

.d-j-c-flex-row {
	flex-direction: row !important;
}

.d-j-c-flex-between {
	justify-content: space-between;
}

.d-j-c-center {
	justify-content: center !important;
}

.d-j-c-left {
	justify-content: left !important;
}

.d-j-c-right {
	justify-content: right !important;
}

.d-align-items-c {
	align-items: center !important;
}

.d-j-c-initial {
	justify-content: initial !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.align-content-center {
	align-content: center !important;
}

.align-item-end {
	align-items: flex-end !important;
}

.align-item-center {
	align-items: center !important;
}

.align-items-center {
	align-items: center !important;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-s-fe {
	align-self: flex-end;
}

.d-flex-colum {
	flex-direction: column;
}

// text utils
.fw-bold {
	font-weight: 700 !important;
}

.text-muted {
	--bs-text-opacity: 1;
	color: #6c757d !important;
}

small,
.small {
	font-size: 0.875em !important;
}

.lh-base {
	line-height: 1.5 !important;
}

@media (min-width: 1200px) {
	h4,
	.h4 {
		font-size: 1.5rem !important;
	}
}

/*******************
Paddings
*******************/
html body {
	.p-20 {
		padding: 20px;
	}

	.p-l-5 {
		padding-left: 5px !important;
	}

	.p-l-10 {
		padding-left: 10px;
	}

	.p-l-20 {
		padding-left: 20px !important;
	}

	.p-l-40 {
		padding-left: 40px;
	}

	.p-t-24 {
		padding-top: 24px;
	}

	.p-b-24 {
		padding-bottom: 24px;
	}

	.p-r-0 {
		padding-right: 0;
	}

	.p-r-5 {
		padding-right: 5rem;
	}

	.p-r-10 {
		padding-right: 10px;
	}

	.p-r-20 {
		padding-right: 20px;
	}

	.p-r-40 {
		padding-right: 40px;
	}

	.p-t-10 {
		padding-top: 10px;
	}

	.p-t-20 {
		padding-top: 20px;
	}

	.p-b-0 {
		padding-bottom: 0 !important;
	}

	.p-b-10 {
		padding-bottom: 10px;
	}

	.p-b-20 {
		padding-bottom: 20px;
	}
}

.p-2 {
	padding: 0.5rem;
}

.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.ps-2 {
	padding-left: 0.5rem;
}

.pe-2 {
	padding-right: 0.5rem;
}

.p-3 {
	padding: 1rem;
}

.py-3 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.px-3 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.ps-3 {
	padding-left: 1rem;
}

.pe-3 {
	padding-right: 1rem;
}

.px-4 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.px-10 {
	padding-left: 5rem;
	padding-right: 5rem;
}

.pe-5 {
	padding-right: 3rem;
}

/*******************
Margin
*******************/
html body {
	.m-0 {
		margin: 0 !important;
	}

	.m-40 {
		margin: 40px !important;
	}

	.m-l-5 {
		margin-left: 5px;
	}

	.m-l-10 {
		margin-left: 10px !important;
	}

	.m-l-20 {
		margin-left: 20px !important;
	}

	.m-l-30 {
		margin-left: 30px;
	}

	.m-l-40 {
		margin-left: 40px;
	}

	.m-r-4 {
		margin-right: 4px;
	}

	.m-r-5 {
		margin-right: 5px;
	}

	.m-r-10 {
		margin-right: 10px;
	}

	.m-r-20 {
		margin-right: 20px;
	}

	.m-r-30 {
		margin-right: 30px;
	}

	.m-r-40 {
		margin-right: 40px !important;
	}

	.m-t-0 {
		margin-top: 0;
	}

	.m-t-5 {
		margin-top: 5px;
	}

	.m-t-10 {
		margin-top: 10px;
	}

	.m-t-15 {
		margin-top: 15px;
	}

	.m-t-20 {
		margin-top: 20px !important;
	}

	.m-t-25 {
		margin-top: 25px !important;
	}

	.m-t-30 {
		margin-top: 30px;
	}

	.m-t-40 {
		margin-top: 40px;
	}

	.m-b-10 {
		margin-bottom: 10px;
	}

	.m-b-20 {
		margin-bottom: 20px;
	}

	.m-b-30 {
		margin-bottom: 30px;
	}

	.ml-auto {
		margin-left: auto;
	}

	// Small devices (landscape phones, 576px and up) (sm)
	// Medium devices (tablets, 768px and up) (md)
	.ml-md-10 {
		@media screen and (min-width: 678px) {
			margin-left: 10px;
		}
	}

	.ml-md-20 {
		@media screen and (min-width: 678px) {
			margin-left: 20px;
		}
	}

	.ml-md-30 {
		@media screen and (min-width: 678px) {
			margin-left: 30px;
		}
	}

	.ml-md-40 {
		@media screen and (min-width: 678px) {
			margin-left: 40px;
		}
	}

	.mr-md-10 {
		@media screen and (min-width: 678px) {
			margin-right: 10px;
		}
	}

	.mt-md-10 {
		@media screen and (min-width: 678px) {
			margin-top: 10px;
		}
	}

	// Large devices (desktops, 992px and up) (lg)
	// Extra large devices (large desktops, 1200px and up) (xl)
}

.m-1 {
	margin: 0.25rem !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.mx-2 {
	margin-left: 0.5rem !important;
	margin-right: 0.5rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.ms-2 {
	margin-left: 0.5rem !important;
}

.me-2 {
	margin-right: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.mx-3 {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.my-4 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.ms-4 {
	margin-left: 1.25rem !important;
}

.mat-icon-small {
	margin-left: 0.5rem;
	font-size: 1.1rem !important;
	width: 1.1rem !important;
	height: 1.1rem !important;
}

.text-gray {
	color: gray;
}

.text-green {
	color: green;
}

/*******************
font weight
*******************/
html body {
	.font-light {
		font-weight: 300;
	}

	.font-medium {
		font-weight: 500;
	}

	.font-10 {
		font-size: 10px;
	}

	.font-12 {
		font-size: 12px !important;
	}

	.font-14 {
		font-size: 14px;
	}

	.font-17 {
		font-size: 17px;
	}
}

/*******************
Border
*******************/
html body {
	.b-0 {
		border: none;
	}
}

/*******************
Thumb size
*******************/

.width-100-percentage {
	width: 100% !important;
}

.width-85-percentage {
	width: 85%;
}

.width-70-percentage {
	width: 70%;
}

.width-80-percentage {
	width: 80%;
}

.max-content {
	max-width: 55px;
}

.width-80 {
	width: 80px;
}

html body {
	.width-1024 {
		max-width: 1024px;
		width: 100%;
		margin-top: 40px;
	}

	.width-860 {
		max-width: 860px;
		margin-top: 40px;
		min-width: 700px;
		width: 100%;
	}
}

.width-10 {
	width: 10px !important;
}

.width-14 {
	width: 14px !important;
}

.width-140 {
	width: 140px !important;
}

.width-170 {
	max-width: 170px !important;
}

.width-200 {
	max-width: 200px !important;
}

.width-250 {
	max-width: 250px !important;
}

.width-300 {
	width: 300px !important;
}

.width-150 {
	width: 150px !important;
}

.width-200 {
	width: 200px;
}

.width-75 {
	width: 75px !important;
}

.width-400 {
	width: 400px !important;
}

.width-400 {
	width: 400px;
}

.width-100 {
	max-width: 100px;
}

.text-wrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*******************
Text Colors
*******************/

.text-danger {
	color: $filed-coral !important;
}

html body {
	.text-center {
		text-align: center;
	}

	.text-right {
		text-align: right;
	}

	.text-left {
		text-align: left;
	}
}

/*******************
Background Colors
*******************/

.bg-white {
	background: $white !important;
}

.dashboard-type {
	padding: 4px 14px;
	border-radius: 13px;
	border: 2px solid rgba($white, 0);
	top: 10px;
	font-size: 9px;
	letter-spacing: 1.41px;
	right: 15px;
	z-index: 2;
}

.dashboard-type-performance {
	background: rgba($filed-blue, 0.2) 0 0 no-repeat padding-box;
	color: $filed-blue;

	&.dashboard-type-selected {
		border: 2px $filed-blue solid;
	}
}

.dashboard-type-leads {
	background: rgba($mint, 0.2) 0 0 no-repeat padding-box;
	color: $mint;

	&.dashboard-type-selected {
		border: 2px $mint solid;
	}
}

.dashboard-type-traffic {
	background: rgba($filed-coral, 0.2) 0 0 no-repeat padding-box;
	color: $filed-coral;

	&.dashboard-type-selected {
		border: 2px $filed-coral solid;
	}
}

.dashboard-type-roas {
	background: rgba($yellow, 0.2) 0 0 no-repeat padding-box;
	color: $yellow;

	&.dashboard-type-selected {
		border: 2px $yellow solid;
	}
}

/*******************
froms
*******************/

.mat-form-field-underline {
	background-color: rgba(11, 11, 11, 0.25);
}

.mat-form-field {
	width: 100%;
	color: $filed-dark-gray;
}

/*******************
Mat card
*******************/
.mat-card {
	margin: auto;
	box-shadow: 2px 2px 12px $filed-lighter-gray !important;
	border-radius: 5px !important;
	padding: 0 !important;

	.mat-card-content {
		padding: 24px;
		font-size: 14px;
		line-height: 23px;
	}

	.mat-card-image {
		width: calc(100% + 0px);
		margin: 0;
	}

	.mat-card-header {
		padding: 12px;

		.mat-card-title {
			margin-bottom: 2px;
			font-size: 20px;
		}

		.mat-card-subtitle,
		.mat-card-subtitle:not(:first-child) {
			margin-bottom: 0;
			margin-top: 0;
			font-size: 14px;
		}

		&.text-white {
			.mat-card-title {
			}

			.mat-card-subtitle {
				color: $filed-lighter-gray;
			}
		}
	}

	.mat-card-title {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.mat-card-subtitle {
		margin-bottom: 20px;
	}

	.mat-card-actions {
		margin: 0 !important;
	}
}

.card-title {
	color: $darkest-gray;
	font-size: 15px !important;
	font-weight: 400;
	display: flex;
	flex-direction: row;
}

.mat-button-on-card {
	padding: 8px !important;
	border: 1px solid $light-blue !important;
	border-radius: 10px !important;
	line-height: 10px !important;
	margin-right: 15px !important;

	&:focus {
		color: $dark-mint !important;
	}
}

// Mat non expansion card
.mat-no-expansion-card {
	padding: 25px 30px !important;
	box-shadow: 3px 3px 15px $filed-lighter-gray !important;
	border-radius: 10px !important;
	margin-bottom: 18px;

	.mat-no-expansion-card-title {
		min-width: 20%;
		padding: 0 20px 0 0;
		font-size: 15px;
		color: $darkest-gray;
		font-weight: 400;
		line-height: inherit;
	}

	.mat-no-expansion-card-body {
		width: calc(80% - 30px);
		line-height: inherit;
		font-size: 15px;
	}
}

.mat-flex-dialog .mat-dialog-container > * {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: stretch;
	justify-content: space-evenly;
}

/*******************
Grid
*******************/
.row {
	margin: 0 -10px;
}

/*******************
Table
*******************/

// .mat-menu-item:hover:not([disabled]) {
// 	background-color: rgba($filed-blue, 0.2);
// 	color: $filed-blue;
// 	transition: 0.3s all ease;
// }

.mat-tab-labels {
	background: $white;
}

/*******************
Sparkline chart tooltip
*******************/

.button-row button {
	margin-right: 2px;
	margin-left: 2px;
}

.line-break {
	border-bottom: 1px solid $filed-dark-gray;
}

.dialog-without-padding {
	mat-dialog-container {
		padding: 0 !important;

		& .mat-tab-body-wrapper {
			height: 600px;
			padding: 15px;
		}
	}
}

.rules .mat-dialog-container {
	padding: 0 !important;
}

.add-card .mat-dialog-container {
	padding: 0 !important;
}

.additional-filter .mat-dialog-container {
	padding: 0 !important;
}

.attribution-window .mat-dialog-container {
	padding: 0 !important;
}

.create-offer .mat-dialog-container {
	padding: 0 !important;
}

.attachOfferDialog .mat-dialog-container {
	padding: 0 !important;
}

.unsubscribeDialog .mat-dialog-container {
	padding: 0 !important;
}

.activateOffers .mat-dialog-container {
	padding: 0 !important;
}

.addBillingDetailsDialog .mat-dialog-container {
	padding: 0 !important;
}

.no-select {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                            supported by Chrome and Opera */
}

// mat-expansion-campaign-builder
.filed-expansion-panel-card {
	.mat-expansion-panel,
	.mat-card {
		box-shadow: 3px 3px 15px $filed-lighter-gray !important;
		border-radius: 10px !important;
		margin-bottom: 18px;

		.mat-form-field-label {
			color: $filed-dark-gray;
			font-weight: 400;
			font-size: 14px;
		}

		.mat-expansion-panel-header {
			&:hover {
				background: none !important;
			}

			&:hover .mat-expansion-indicator::after {
				color: $filed-blue;
				transition: color 0.3s ease;
			}
		}
	}

	.mat-input-element {
	}

	.currency-input {
		position: relative;
		left: 25px;
	}

	.currency-text {
		color: $filed-light-gray;
		font-size: 15px;
		font-weight: 500;
		position: relative;
		left: 15px;
		bottom: 5px;
	}

	.mat-expansion-panel-body {
		.mat-button-focus-overlay {
			background: none;
		}

		button.mat-button {
			padding: 0 !important;
			margin: 0 25px 0 0;
			border: none !important;
			line-height: inherit !important;

			&:hover .mat-button-wrapper {
				color: $filed-blue;
			}
		}

		.mat-button-wrapper {
			text-transform: initial;
			color: $filed-blue;
			font-weight: 500;
			font-size: 14px;
			transition: color 0.3s ease;

			i {
				vertical-align: initial;
				margin-right: 3px;
			}
		}

		.mat-button[disabled] {
			opacity: 0.55;

			&:hover .mat-button-wrapper {
				color: $filed-blue;
			}
		}
	}

	> .mat-expansion-panel:not(.url-parameter-panel),
	.exception-panel {
		overflow: visible;
		display: flex;
		position: relative;
		flex-wrap: wrap;

		.mat-expansion-panel-content {
			transition: top 218ms cubic-bezier(0.4, 0, 0.2, 1);
		}

		&.mat-expanded {
			padding: 25px 30px;
			margin-bottom: 20px;

			.mat-expansion-panel-body {
				padding: 0;
			}
		}

		> .mat-expansion-panel-header {
			padding: 30px 30px;
			min-height: 60px;

			.mat-expansion-panel-header-title {
				flex-grow: 0;
			}

			&[aria-expanded='true'] {
				width: 20%;
				height: auto;
				align-items: flex-start;
				padding: 0 20px 0 0;

				.mat-content {
					margin: 0;
				}

				+ .mat-expansion-panel-content {
					opacity: 1;
					top: 0;
				}

				.mat-expansion-indicator {
					position: absolute;
					right: 0;
					margin: 5px 30px 10px 25px;
				}

				.mat-expansion-panel-header-title {
					margin: 0;
				}
			}

			&[aria-expanded='false'] {
				width: 100%;

				.mat-content {
					animation: animateMatContent 218ms;
				}

				.mat-expansion-panel-header-title {
					min-width: 20%;
				}

				+ .mat-expansion-panel-content {
					opacity: 0;
					top: 30px;
				}
			}
		}

		> .mat-expansion-panel-content {
			width: calc(80% - 30px);
		}

		.filed-expansion-panel-card__body {
			&.force-disable {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 1;
					background: $white;
					opacity: 0.57;
				}
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.subtitle {
		flex-flow: row wrap;
		box-sizing: border-box;
		display: flex;
		max-width: 80%;
		padding-right: 1rem;
		flex-grow: 1;
	}

	.border_input {
		border-bottom: 2px solid $filed-blue;
	}

	.mat-nested-tree-node,
	.mat-tree-node {
		color: $filed-dark-gray;
		font-weight: 400;
		font-size: 14px;
	}

	.mat-checkbox-disabled {
		color: $filed-light-gray;
	}

	.mat-slide-toggle-content {
		color: $filed-light-gray;
		font-weight: 400;
		font-size: 15px;
	}

	.currency-label {
		color: $filed-light-gray;
	}

	.mat-expansion-indicator::after {
		padding: 0px;
		height: 7px;
		width: 7px;
		border-width: 0 2px 2px 0;
	}

	.material-icons {
		color: $filed-dark-gray;
	}

	// advanced expansion panel class
	.advanced-expansion {
		width: 100%;
		margin-top: 10px;
		color: $filed-dark-gray;
		font-weight: 400;
		font-size: 15px;

		.mat-expansion-panel:not([class*='mat-elevation-z']) {
			box-shadow: none !important;
		}

		.mat-expansion-panel {
			border: transparent;

			&:not(.mat-expanded)
				.mat-expansion-panel-header:not([aria-disabled='true']):hover {
				border: transparent;
			}

			.mat-expansion-panel-header {
				flex-direction: row;
				padding: 0 5px 0 0;

				&:hover {
					background: transparent;
					border: transparent;
				}

				.mat-content {
					flex: none;
				}

				.mat-expansion-indicator {
					margin-left: 49px;
				}

				.mat-expansion-panel-header-title {
					color: $filed-dark-gray;
					font-weight: 400;
					font-size: 14px;
				}
			}
		}

		.mat-expansion-panel-content {
			margin-top: 0;

			.mat-list-text {
				color: $filed-dark-gray;
				font-weight: 400;
				font-size: 14px;
			}
		}

		.mat-expansion-panel-body {
			padding: 0 20px;
		}

		.mat-expansion-indicator::after {
			padding: 0px;
			height: 6px;
			width: 6px;
			border-width: 0 2px 2px 0;
			color: $filed-dark-gray;
		}
	}
}

lolly-btn03.grey,
lolly-btn03.grey {
	& > button.k-button.k-primary {
		background-color: $LightGrey02;
		border-color: $filed-lighter-gray;
		color: $filed-dark-gray;

		&:focus {
			box-shadow: 0 0 0 3px #bdbdbc;
		}

		&:hover {
			background-color: $filed-gray-menu;
		}

		.filed-font {
			color: $filed-dark-gray;
		}
	}
}

.mat-select-placeholder {
	margin: 0;
}

.filed-expansion-buttons-menu {
	display: flex;

	.text-right {
		margin-left: auto;
	}
}

.mat-expansion-panel-heading {
	color: $darkest-gray;
	font-weight: 500;
}

.mat-expansion-panel-text {
	color: $filed-dark-gray;
}

// TODO: MUST DEFINITELY MOVE THIS BLOCK FROM THIS FILE
// Not doing now because of lack of time. Usage is within multiple components.
// Main usage in Campaign Builder steps
.objectives {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.objective-group {
		width: 130px;
		height: 150px;
		margin-left: 0px;
		margin-right: 18px;
		margin-bottom: 8px;
		padding-left: 5px;
		padding-right: 5px;
		border: 2px solid $filed-lightest-gray;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		position: relative;
		flex-wrap: wrap;
		transition: transform 0.2s ease-out;
		border-radius: 5px;
		background: $filed-lightest-gray 0% 0% no-repeat padding-box;

		&:focus {
			border: 2px solid $filed-blue;
		}

		.objective-group--circle {
			width: 32px;
			height: 32px;
			align-self: center;
			transition: width 0.5s, height 0.5s ease;
			opacity: 0.6;
		}

		.objective-group--text {
			margin-top: 12px;
			align-self: center;
			color: $darkest-gray;
			font-weight: 400;
			font-size: 14px;
		}

		.objective-group--description {
			font-size: 12px;
			height: 0;
			clear: both;
			visibility: hidden;
			opacity: 0;
			transition: visibility 0s, opacity 0.5s linear;
		}
	}

	.objective-group:not(.disabled):hover {
		display: block;
		justify-content: left;
		align-items: flex-start;
		border: 2px solid $filed-blue;
		transform: scale(1.05);

		.objective-group--circle {
			width: 16px;
			height: 16px;
			margin-top: 10px;
			float: left;
			transition: width 0.5s, height 0.5s ease;
			position: absolute;
		}

		.objective-group--text {
			float: left;
			display: inline-flex;
			margin-top: 8px;
			margin-left: 20px;
		}

		.objective-group--description {
			height: 100px;
			visibility: visible;
			opacity: 1;
			transition: visibility 0s, opacity 0.5s linear;
			display: flex;
			align-items: center;
		}

		&.active {
			&:before {
				left: 53px;
			}
		}
	}

	.objective-group {
		&.disabled {
			cursor: not-allowed;
		}
	}
}

.space-between {
	justify-content: space-between;
}

.space-around {
	justify-content: space-around;
}

.space-evenly {
	justify-content: space-evenly;
}

.mat-form-field-fix {
	margin-top: -17px;
}

.custom-mat-selection-list {
	&.mat-list-base {
		.mat-list-item,
		.mat-list-base .mat-list-option {
			height: 24px;
		}

		.mat-list-item .mat-list-item-content-reverse,
		.mat-list-base .mat-list-option .mat-list-item-content-reverse {
			flex-direction: row;
			padding: 0;
		}

		.mat-list-option.mat-list-option
			.mat-list-item-content-reverse
			.mat-list-text {
			margin-left: 8px;
			font-size: 14px;
		}
	}
}

.mat-menu-panel {
	min-height: 0 !important;
}

/*This is class is meant to style the browse locations component in Campaign Builder- Step 3*/
.campaign-builder-targeting-locations-browse-menu {
	&.mat-menu-panel {
		overflow: auto !important;
		-webkit-overflow-scrolling: touch !important;
		border-radius: 4px !important;
		outline: 0 !important;
		min-width: 100% !important;
		max-width: 100% !important;
	}

	.mat-menu-content:not(:empty) {
		padding: 1rem !important;
		max-height: 40vh !important;
	}

	.mat-menu-content {
		overflow-y: auto !important;
		width: 100% !important;
	}
}

.flex {
	display: flex;

	&-center {
		align-items: center;
	}
}

.one_line_text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 13px;
}

@keyframes animateMatContent {
	from {
		margin: 30px 0 0 0;
	}

	to {
		margin: 0;
	}
}

.error-message {
	color: $filed-coral;
	font-weight: 500;
}

.not-visible {
	visibility: hidden;
}

.d-none {
	display: none;
}

.cursor-default {
	cursor: default;
}

.pointer-events-none {
	pointer-events: none;
}

.custom-calculated-measure {
	width: 80% !important;

	.mat-dialog-container {
		background: $filed-lightest-gray;
		border-radius: 10px;
		padding: 50px;
	}
}

.custom-matdialog-no-padding {
	padding: 0;
	height: 43rem;
}

@media only screen and (max-device-width: 480px) {
	.custom-matdialog-no-padding {
		height: 42rem;
		max-width: 40% !important;
	}
}

@media only screen and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 3.5) {
	.custom-matdialog-no-padding {
		height: 48rem;
		max-width: 35% !important;
	}
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
	.custom-matdialog-no-padding {
		height: 45rem;
		max-width: 30% !important;
	}
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
	.custom-matdialog-no-padding {
		height: 42rem;
		max-width: 30% !important;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
	.custom-matdialog-no-padding {
		height: 56rem;
		max-width: 60% !important;
	}
}

@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	.custom-matdialog-no-padding {
		height: 80rem;
		max-width: 65% !important;
	}
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
	.custom-matdialog-no-padding {
		height: 36rem;
		max-width: 29% !important;
	}
}

@media screen and (device-aspect-ratio: 40/71) {
	.custom-matdialog-no-padding {
		height: 33rem;
		max-width: 30% !important;
	}
}

@media screen and (device-aspect-ratio: 375/667) {
	.custom-matdialog-no-padding {
		height: 39rem;
		max-width: 30% !important;
	}
}

.matdialog-no-padding .mat-dialog-container {
	padding: 0;
}

.gradient-underline {
	position: relative;

	&:after {
		content: '\A';
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		top: 100%;
		background: linear-gradient(45deg, $filed-lightest-gray, transparent);
	}
}

button.btn-round-corners {
	border-radius: 20px;
}

.btn-round-corners-container {
	button {
		border-radius: 20px;
	}
}

.button-confirm {
	margin-left: auto;
}

.dialog-container-p-l-0 {
	.mat-dialog-container {
		padding: 0;
		background-color: $filed-lightest-gray;
	}
}

mat-form-field.form-field-removed-outline {
	.mat-form-field-flex {
		margin: 0;
		padding: 0;
		border-radius: 7px;
		border: 1px solid $filed-lighter-gray;
		cursor: text;
	}

	&.mat-form-field-disabled {
		.mat-form-field-flex {
			cursor: default !important;
		}
	}

	.mat-form-field-outline {
		display: none;
	}

	.mat-form-field-infix {
		border: none;
		padding: 15px;
	}
}

.transition-button {
	position: fixed;

	&.mat-button {
		border-radius: 50px;
	}

	&:hover {
		.mat-button-wrapper {
			width: 100%;
			position: relative;
			z-index: 1;
			color: $white;

			.text {
				opacity: 1;
				display: block;
			}
		}
	}

	.mat-button-wrapper {
		display: flex;
		align-items: center;
		height: 36px;
		width: 24px;
		overflow: hidden;
		transition: all 850ms ease-in-out;

		.text {
			opacity: 0;
			display: none;
		}
	}

	.mat-button-focus-overlay {
		background: $filed-blue;
	}

	&.mat-button:hover .mat-button-focus-overlay,
	&.mat-stroked-button:hover .mat-button-focus-overlay {
		opacity: 1;
	}
}

app-dashboard-grid {
	gridster-item {
		&.flex-gridster {
			display: flex !important;
			padding: 15px;
		}
	}
}

.widget-container {
	position: relative;
	height: 100%;
	width: 100%;

	*:first-child {
		position: relative;
		height: 100%;
		width: 100%;
	}

	.chart {
		height: 100% !important;

		& > div:first-child {
			height: 100% !important;
			width: 100% !important;
			position: relative !important;
		}
	}
}

.chart-container[cdkdrag] * {
	margin: 0 !important;
}

.table-configurations-sidebar-column.cdk-drag-preview {
	background: $filed-dark-gray;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	> * {
		color: $white;
		user-select: none;
	}

	> span {
		width: 100%;
		padding: 0 5px;
		font-size: 13px;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.mat-icon {
		font-size: 18px;
		height: 18px;
		width: 18px;

		&.sidebar-column-remove {
			display: none !important;
		}
	}
}

.shepherd-elements {
	box-shadow: 0px 13px 36px #001a3d0f !important;
	border-radius: 4px !important;
	opacity: 0;
	outline: none;
	transition: opacity 0.3s, visibility 0.3s;
	visibility: hidden;
	width: 402px;
	padding: 16px;
	font-family: 'Gilroy';
	position: relative;

	.shepherd-arrow {
		visibility: hidden;
		display: none !important;
	}

	.shepherd-button-close {
		position: absolute !important;
		top: 10px !important;
		right: 0 !important;
		background: unset !important;
		padding: 0 !important;
		height: 24px !important;
		width: 24px !important;

		img {
			height: 14px;
			width: 14px;
		}
	}

	&.mr-inline {
		margin: 0 20px !important;
	}

	&.mr-inline-l {
		margin: 0 54px 0 0 !important;
	}

	&.mr-inline-vertical {
		margin: 20px 0 !important;
	}

	&.shepherd-has-title {
		.shepherd-content {
			.shepherd-header {
				display: block;
				width: 100%;
				background-color: #ffffff;
				padding: 0;

				h3 {
					text-align: left;
					font-weight: 600;
					font-size: 18px;
					letter-spacing: 0px;
					color: #000;
					font-family: 'Gilroy';
				}
			}

			.shepherd-text {
				white-space: pre-wrap;
				text-align: left;
				color: #7a7a7a;
				opacity: 1;
				padding: 0;
				margin-top: 16px;
				margin-bottom: 16px;
				font-weight: normal;
				font-size: 14px;
				letter-spacing: 0px;
				font-family: 'Gilroy';
			}

			.shepherd-footer {
				display: flex;
				justify-content: space-between;
				padding: 0;

				.shepherd-button-secondary {
					border-radius: 5px;
					height: 48px;
					padding: 0;
					background-color: #ffffff;
					color: #1a73e9;
					border: none;
					font-weight: 600;
					font-size: 16px;
					font-family: 'Gilroy' !important;
					letter-spacing: 0.16px;
					cursor: pointer;
				}

				.shepherd-button-primary {
					height: 48px;
					padding: 0px 26px;
					background-color: #4a4a4a;
					color: #ffffff;
					border: none;
					font-weight: 600;
					font-size: 16px;
					font-family: 'Gilroy' !important;
					letter-spacing: 0.14px;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 6px;
					cursor: pointer;
					transition: 0.2s all;

					img {
						height: 15px;
						width: 15px;
					}

					&:hover {
						background-color: #121212;
						color: #ffffff;
					}

					&.disabled {
						opacity: 0.4;
						pointer-events: none;
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1599px) {
		padding: 1.5rem;

		&.shepherd-has-title {
			.shepherd-content {
				.shepherd-text {
					text-align: left;
					margin-bottom: 16px;
					margin-top: 9px;
					font-size: 14px;
					line-height: 23px;
					letter-spacing: 0.16px;
				}

				.shepherd-footer {
					display: flex;
					justify-content: space-between;
					padding: 0;
				}
			}
		}
	}
}

.visibility-hidden {
	visibility: hidden;
}

@include media-below($breakpoint-sm) {
	.mat-snack-bar-handset .mat-snack-bar-container {
		max-width: 300px !important;
		margin-left: auto !important;
		margin-right: auto !important;
	}
}

.cdk-overlay-dark-backdrop {
	background: rgba(11, 11, 11, 0.64);
}

.reporting-container {
	.mat-dialog-container {
		background: $filed-lightest-gray;
		padding: 0;
	}

	height: 80% !important;
	width: 60% !important;
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

// progress bar
.mat-progress-bar-fill::after {
	background-color: $light-blue;
}

.mat-progress-bar-buffer {
	background-color: $filed-lightest-gray;
}

// use it in a new tag or be sure display: block or inline-block is set
.capitalize-first-letter {
	text-transform: lowercase;

	&:first-letter {
		text-transform: capitalize;
	}
}

[hidden] {
	display: none !important;
}

span.mat-form-field-label-wrapper {
	margin: 0;
}

//scroll bar style

// ::-webkit-scrollbar {
// 	width: 6px;
// 	height: 0.52vh;
// 	background: #ededed;
// }

// ::-webkit-scrollbar-thumb {
// 	-webkit-border-radius: 6px;
// 	border-radius: 6px;
// 	background: #4a4a4a;
// }

// ::-webkit-scrollbar-thumb:window-inactive {
// 	background: #4a4a4a;
// }

::-webkit-scrollbar {
	width: 14px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px 6px #ededed;
	border: solid 4px transparent;
}

::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 6px 6px #4a4a4a;
	border: solid 4px transparent;
	-webkit-border-radius: 8px;
	border-radius: 8px;
}

.cursor-pointer {
	cursor: pointer !important;
}

.scrollable {
	height: 25rem;
	padding-top: 0.7rem;
	overflow: auto;
	scroll-behavior: smooth;

	&.wide {
		width: calc(100% + 2rem);
		transform: translateX(-2rem);
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&.short {
		height: 23rem;
	}

	&.long {
		height: 27rem;
	}

	&.no-padding {
		padding: 0;
	}
}

//*********************************
// Creative Builder dialog pop-ups
//*********************************

.creative-builder-save-as-custom-dialog {
	.mat-dialog-container {
		padding: 20px 24px 24px 24px;
		border-radius: 10px;
	}

	.dialog-box {
		.mat-form-field-outline-start {
			display: none;
		}

		.mat-form-field-outline-end {
			border: 2px solid currentColor;
			border-radius: 8px;
		}

		.mat-form-field-appearance-outline .mat-form-field-flex {
			padding: 0 1.5em 0 1.5em;
			margin-top: -0.25em;
			position: relative;
		}

		.mat-form-field-infix {
			border-top: 5px solid transparent;
		}

		.dialog-inner {
			.dialog-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-left: 10px;

				.dialog-title {
					text-align: left;
					font: 500 20px/24px Roboto;
					letter-spacing: 0;
					color: $filed-dark-gray;
				}

				.dialog-close {
					color: #9b9b9bfa;
				}

				.dialog-close:hover {
					cursor: pointer;
				}
			}

			.mat-divider {
				border-top-width: 2px;
				border-color: $filed-blue;
				margin: 10px 0 50px 0;
				width: 610px;
				position: relative;
				left: -24px;
			}

			.dialog-form {
				padding: 0px 30px 0px 30px;

				.creative-name-container {
					.form-description {
						text-align: left;
						font: 400 14px/14px Roboto;
						letter-spacing: 0.28px;
						color: $filed-light-gray;
						margin-bottom: 5px;
						display: flex;
					}

					.creative-name-field {
						.mat-form-field-flex {
							height: 54px;
							display: flex;
							align-items: center;
						}

						.mat-form-field-should-float {
							.mat-form-field-outline {
								color: $filed-blue;
							}
						}
					}
				}

				.project-radio {
					margin: 20px 0 40px 0;

					.mat-radio-group {
						display: flex;

						.mat-radio-button {
							margin-right: 70px;
						}

						.mat-radio-label-content {
							text-align: left;
							font: 400 16px/14px Roboto;
							letter-spacing: 0.32px;
							color: $filed-light-gray;
							padding-left: 12px;
						}
					}
				}

				.project-name-container,
				.select-project-container {
					.mat-form-field-prefix {
						margin: 0 8px 4px 0;

						.mat-icon {
							display: inline-block;
							color: $icon-gray;
							height: 23px;
							width: 23px;
							opacity: 0.57;
						}
					}

					.form-description {
						text-align: left;
						font: 400 14px/14px Roboto;
						letter-spacing: 0.28px;
						color: $filed-light-gray;
						margin-bottom: 5px;
					}

					.project-name-field {
						.folder-menu {
							overflow: overlay;
						}

						.input-wrapper {
							height: 54px;
						}

						.mat-form-field-flex {
							height: 54px;
							display: flex;
							align-items: center;
							color: $filed-blue;
						}

						.mat-form-field-should-float {
							.mat-form-field-outline {
								color: $filed-blue;
							}
						}
					}
				}
			}

			.mat-dialog-actions {
				margin-top: 50px;
				display: flex;
				justify-content: space-between;
				margin-bottom: 0;
				padding-left: 20px;

				.cancel-button {
					text-align: left;
					font: 600 14px/17px Roboto;
					letter-spacing: 0.27px;
					color: $filed-dark-gray;
					opacity: 0.5;
				}

				.confirm-button {
					width: 117px;
					height: 43px;
					background: $filed-blue 0% 0% no-repeat padding-box;
					border-radius: 50px;
					text-align: left;
					font: Bold 14px/19px Roboto;
					letter-spacing: 0.27px;
					color: $white;
					text-align: center;
					margin-left: 20px;
				}
			}
		}

		.mat-radio-outer-circle {
			border-width: 1px;
		}

		.mat-radio-inner-circle {
			height: 26px;
			left: -3px;
			top: -3px;
			width: 26px;
		}

		.mat-radio-label-content {
			padding-left: 14.5px;
		}

		.resize-panel {
			box-shadow: none;

			.resize-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.width {
					width: 40%;
				}

				.height {
					width: 40%;
				}
			}
		}

		.actions-container {
			margin-top: 20px;
			display: flex;
			justify-content: center;
		}
	}

	.asterix {
		text-align: left;
		font: 400 28px/14px Roboto;
		letter-spacing: 0.56px;
		color: $error;
		opacity: 0.8;
	}
}

// new design dialog
.creative-builder-create-new-custom-dialog {
	.folder-menu {
		width: 368px;
		max-width: 368px;
		height: 170px;

		.mat-menu-item {
			height: 34px;
			line-height: 0;
		}

		.mat-list {
			padding: 0;

			.mat-list-item {
				height: 34px;

				.mat-list-item-content {
					padding: 0;
				}
			}
		}
	}

	.mat-dialog-container {
		padding: 20px 24px 24px 24px;
		border-radius: 10px;
		overflow-x: hidden;
	}

	.dialog-box {
		.mat-form-field-outline-start {
			display: none;
		}

		.mat-form-field-outline-end {
			border: 2px solid currentColor;
			border-radius: 8px;
		}

		.mat-form-field-appearance-outline .mat-form-field-flex {
			padding: 0 1.5em 0 1.5em;
			margin-top: -0.25em;
			position: relative;
		}

		.mat-form-field-infix {
			border-top: 5px solid transparent;
		}

		.dialog-inner {
			.dialog-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-left: 10px;

				.dialog-title {
					text-align: left;
					font: 500 20px/24px Roboto;
					letter-spacing: 0;
					color: $filed-dark-gray;
				}

				.dialog-close {
					color: #9b9b9bfa;
				}

				.dialog-close:hover {
					cursor: pointer;
				}
			}

			.mat-divider {
				border-top-width: 2px;
				border-color: $filed-blue;
				margin: 10px 0 50px 0;
				width: 610px;
				position: relative;
				left: -24px;
			}

			.dialog-form {
				padding: 0px 30px 0px 30px;

				.creative-name-container {
					.form-description {
						text-align: left;
						font: 400 14px/14px Roboto;
						letter-spacing: 0.28px;
						color: $filed-light-gray;
						margin-bottom: 5px;
						display: flex;
					}

					.creative-name-field {
						.mat-form-field-flex {
							height: 54px;
							display: flex;
							align-items: center;
						}

						.mat-form-field-should-float {
							.mat-form-field-outline {
								color: $filed-blue;
							}
						}
					}
				}

				.project-radio {
					margin: 20px 0 40px 0;

					.mat-radio-group {
						display: flex;

						.mat-radio-button {
							margin-right: 70px;
						}

						.mat-radio-label-content {
							text-align: left;
							font: 400 16px/14px Roboto;
							letter-spacing: 0.32px;
							color: $filed-light-gray;
							padding-left: 12px;
						}
					}
				}

				.project-name-container,
				.select-project-container {
					.mat-form-field-prefix {
						margin: 0 8px 4px 0;

						.mat-icon {
							display: inline-block;
							color: $icon-gray;
							height: 23px;
							width: 23px;
							opacity: 0.57;
						}
					}

					.form-description {
						text-align: left;
						font: 400 14px/14px Roboto;
						letter-spacing: 0.28px;
						color: $filed-light-gray;
						margin-bottom: 5px;
					}

					.project-name-field {
						.folder-menu {
							overflow: overlay;
						}

						.input-wrapper {
							height: 54px;
						}

						.mat-form-field-flex {
							height: 54px;
							display: flex;
							align-items: center;
							color: $filed-blue;
						}

						.mat-form-field-should-float {
							.mat-form-field-outline {
								color: $filed-blue;
							}
						}
					}
				}

				.media-list-container {
					margin-top: 30px;
					text-align: left;
					font: 400 16px/14px Roboto;
					letter-spacing: 0.32px;
					color: $filed-light-gray;

					.media-item {
						display: flex;
						flex-direction: row;
						margin-bottom: 7px;

						.radio-and-title {
							width: 200px;
							margin-right: 50px;

							.mat-radio-label-content {
								font-weight: 400;
							}
						}
					}
				}
			}

			.mat-dialog-actions {
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
				margin-bottom: 0;
				padding-left: 20px;

				.cancel-button {
					text-align: left;
					font: 600 14px/17px Roboto;
					letter-spacing: 0.27px;
					color: $filed-dark-gray;
					opacity: 0.5;
				}

				.confirm-button {
					width: 117px;
					height: 43px;
					background: $filed-blue 0% 0% no-repeat padding-box;
					border-radius: 50px;
					text-align: left;
					font: 600 14px/19px Roboto;
					letter-spacing: 0.27px;
					color: $white;
					text-align: center;
					margin-left: 20px;
				}
			}
		}

		.mat-radio-outer-circle {
			border-width: 1px;
		}

		.mat-radio-inner-circle {
			height: 26px;
			left: -3px;
			top: -3px;
			width: 26px;
		}

		.mat-radio-label-content {
			padding-left: 14.5px;
		}

		.resize-panel {
			margin-top: 20px;
			box-shadow: none;

			.mat-expansion-panel-header-title {
				font: 400 16px/14px Roboto;
				letter-spacing: 0.32px;
				color: $filed-light-gray;
			}
		}

		.resize-container {
			display: flex;
			flex-direction: row;
			margin-top: 25px;

			.width {
				width: 40%;
				margin-right: 40px;
			}

			.height {
				width: 40%;
			}
		}

		.actions-container {
			margin-top: 20px;
			display: flex;
			justify-content: center;
		}
	}

	.asterix {
		text-align: left;
		font: 400 28px/14px Roboto;
		letter-spacing: 0.56px;
		color: $error;
		opacity: 0.8;
	}

	.platform-select {
		margin-top: 20px;
		font: 400 16px/11px Roboto;
		letter-spacing: 0.32px;

		.mat-button-toggle-group {
			width: 100%;
			border-radius: 8px;
			border: unset;
			height: 39px;

			.mat-button-toggle {
				width: 50%;
				border: 2px solid $filed-lightest-gray;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.mat-button-toggle-button {
				height: 100%;
			}

			.instagram-select {
				border-radius: 0 8px 8px 0;
			}

			.facebook-select {
				border-radius: 8px 0 0 8px;
			}

			.mat-button-toggle-checked {
				background-color: $white;
				color: $filed-blue;
				border: 2px solid $filed-blue;
			}

			.facebook-select .mat-button-toggle-checked {
				border-radius: 8px 0 0 8px;
			}

			.instagram-select .mat-button-toggle-checked {
				border-radius: 0 8px 8px 0;
			}
		}

		.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover
			.mat-button-toggle-focus-overlay {
			opacity: 0;
		}

		.mat-button-toggle-appearance-standard
			.mat-button-toggle-label-content {
			line-height: unset;
		}
	}

	::-webkit-scrollbar {
		width: 4px;
	}

	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px $filed-light-gray;
		border-radius: 3px;
	}
}

.color-picker {
	box-shadow: 0px 3px 6px #00000017;
	border-radius: 8px;
	border: none !important;
	display: flex !important;
	flex-direction: column;

	.saturation-lightness {
		border-radius: 6px;

		.cursor {
			border: 2px solid $white;
		}
	}

	.hue-alpha {
		.left {
			display: none !important;
		}

		.right {
			padding: 0px 8px !important;
		}

		.hue {
			border-radius: 90px;
			height: 8px;
			padding-top: 1px;
			padding-left: 4px;

			.cursor {
				background-color: $white;
				border: none !important;
				width: 6px;
				height: 6px;
			}
		}
	}

	.rgba-text {
		display: flex !important;
		flex-direction: row-reverse;
		justify-content: flex-end;
		font: 400 12px/21px Roboto !important;
		letter-spacing: 0px !important;
		color: $filed-light-gray !important;
		align-items: center;

		.box:not(:first-child) {
			padding: 0 24px 0 8px !important;
		}

		.box input {
			width: 25px;
			border: none !important;
			border-bottom: 1px solid $filed-light-gray !important;
			font: 400 12px/21px Roboto !important;
			letter-spacing: 0px !important;
			color: $filed-dark-gray !important;
		}
	}

	.hex-text {
		display: flex !important;
		flex-direction: row-reverse;
		justify-content: flex-end;
		color: $filed-light-gray !important;
		align-items: center;

		.box:not(:first-child) {
			padding: 0 24px 0 8px !important;
		}

		.box input {
			width: 64px !important;
			border: none !important;
			border-bottom: 1px solid $filed-light-gray !important;
			font: 400 12px/21px Roboto !important;
			letter-spacing: 0px !important;
			color: $filed-dark-gray !important;
		}
	}

	.type-policy {
		display: none;
	}

	.box div {
		font: 400 12px/21px Roboto !important;
		letter-spacing: 0px !important;
		color: $filed-light-gray !important;
		margin-right: 0px !important;
	}
}

.chart-container {
	app-widget-loader.table-widget-loader {
		overflow-y: auto;
	}
}

.budget-tooltip {
	.k-tooltip {
		border-color: transparent;
		background-color: transparent;
	}

	.k-tooltip-content {
		background-color: #0064e2;
		width: 15rem;
		height: auto;
		color: #fff;
		padding: 0.8rem;

		border-radius: 0.3125rem;
	}

	.k-callout {
		background-color: transparent;
		color: transparent;
	}
}

// fix for chart2's tooltip
.wrapper,
.chart {
	div {
		.tooltip-item-container {
			display: inline-block;
			margin: 0.5vh;

			span {
				margin: 0;
			}
		}
	}
}

.infoNotification {
	background-color: #e6f2ff;
	color: #08346c;
}

.successNotification {
	background-color: #d8f2f3;
	color: #09494b;
}

.warningNotification {
	background-color: #fff8e7;
	color: #7e5f17;
}

.errorNotification {
	background-color: #fdebe7;
	color: #60100b;
}

.campaign-card-form {
	.mat-card {
		width: 74rem;
		min-height: 43.75rem;
	}

	.card-padding {
		padding: 2.625rem 4.75rem 2.188rem 3.25rem;
	}

	.mat-card-header .mat-card-subtitle:not(:first-child) {
		margin-top: 8px;
	}

	.mat-card-header-text {
		margin: 0;
	}

	.campaign-form-wrapper {
		margin-bottom: 5px;
		width: 100%;
	}

	.campaign-form-wrapper-two {
		margin: 0.188rem 0;
		display: flex;
		justify-content: space-between;
	}

	.campaign-form-label {
		width: 100%;
		display: flex;
	}

	.label-align-center {
		align-items: center;
	}

	.info-icon {
		color: $white;
		background: $filed-light-gray;
		border-radius: 50%;
		height: 1rem;
		width: 1rem;
		font-size: 1rem;
		margin: 0.375rem 0.688rem;
	}

	.form-field-max {
		width: 100%;

		.grid-form {
			display: grid;
			grid-template-columns: auto auto;
			grid-gap: 10px 20px;

			& > div {
				display: flex;
				gap: 5px;
			}
		}
	}

	.form-field-min {
		width: 50%;
	}

	.campaign-form-input {
		width: 20.625rem;
	}

	.campaign-form-row {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.campaign-form-column {
		display: flex;
		flex-direction: column;
	}

	.campaign-form-column-2 {
		display: flex;
		flex-direction: column;
		width: 39rem;
	}

	.k-card-body {
		padding: 1.25rem 1.25rem 0;
	}

	.mat-radio-button ~ .mat-radio-button {
		margin-left: 5.625rem;
	}

	.header-icon {
		color: $filed-blue;
		font-size: 2rem;
		height: 2rem;
		width: 2rem;
		margin-right: 0.813rem;
	}

	.mat-card-title {
		display: flex;
		align-items: center;
	}

	.campaign-form-input-min {
		width: 17.625rem;
		display: flex;
		justify-content: space-between;
		margin-top: 0.5rem;
	}

	.form-field-sm {
		width: 47%;
	}
}

.campaign-card-form-2 {
	.mat-card {
		min-height: 43.75rem;
	}

	.card-padding {
		padding: 2.625rem 4.75rem 2.188rem 3.25rem;
	}

	.mat-card-header .mat-card-subtitle:not(:first-child) {
		margin-top: 8px;
	}

	.mat-card-header-text {
		margin: 0;
	}

	.campaign-form-wrapper {
		margin-bottom: 5px;
		width: 100%;
	}

	.campaign-form-wrapper-two {
		margin: 0.188rem 0;
		display: flex;
		justify-content: space-between;
	}

	.campaign-form-label {
		width: 100%;
		display: flex;
	}

	.label-align-center {
		align-items: center;
	}

	.info-icon {
		color: $white;
		background: $filed-light-gray;
		border-radius: 50%;
		height: 1rem;
		width: 1rem;
		font-size: 1rem;
		margin: 0.375rem 0.688rem;
	}

	.form-field-max {
		width: 100%;

		.grid-form {
			display: grid;
			grid-template-columns: auto auto;
			grid-gap: 10px 20px;

			& > div {
				display: flex;
				gap: 5px;
			}
		}
	}

	.form-field-min {
		width: 50%;
	}

	.campaign-form-input {
		width: 20.625rem;
	}

	.campaign-form-row {
		display: flex;
		width: 100%;
		column-gap: 20px;
	}

	.campaign-form-column {
		display: flex;
		flex-direction: column;
	}

	.campaign-form-column-2 {
		display: flex;
		flex-direction: column;
		width: 39rem;
	}

	.k-card-body {
		padding: 1.25rem 1.25rem 0;
	}

	.mat-radio-button ~ .mat-radio-button {
		margin-left: 5.625rem;
	}

	.header-icon {
		color: $filed-blue;
		font-size: 2rem;
		height: 2rem;
		width: 2rem;
		margin-right: 0.813rem;
	}

	.mat-card-title {
		display: flex;
		align-items: center;
	}

	.campaign-form-input-min {
		width: 17.625rem;
		display: flex;
		justify-content: space-between;
		margin-top: 0.5rem;
	}

	.form-field-sm {
		width: 47%;
	}
}

.dialog-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.dialog-header {
		padding: 1rem 0;
	}

	.dialog-image {
		width: 28rem;
	}

	.dialog-text {
		padding: 0 16rem;
	}
}

.campaign-dialog-action-buttons {
	display: flex;
	justify-content: flex-end;
	padding: 0 2.86rem;
}

.tooltip-white {
	background-color: $white !important;
	color: $darkest-gray !important;
	box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2),
		0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
	margin: 0.313rem !important;
}

.tooltip-blue {
	background-color: $filed-blue !important;
	color: $white !important;
	font-size: 1rem;
	box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2),
		0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
	margin: 0.313rem !important;
}

.toast-wrapper {
	z-index: $highest-z-index !important;
}

.k-list .k-item[id$='-0'] {
	color: $filed-blue;
}

.custom-grid {
	.master-table-wrapper {
		box-shadow: none !important;
	}

	.master-table-wrapper .header-wrapper {
		border-bottom: none !important;
	}

	.ag-theme-alpine .ag-header {
		border-bottom: none;
		background: white;
	}

	.ag-theme-alpine .ag-root-wrapper {
		border: none !important;
	}

	.ag-theme-alpine .ag-row:not(.ag-row-first) {
		border-top-style: none !important;
		border: none !important;
	}

	.ag-theme-alpine .ag-row-odd {
		background-color: #ffffff !important;
	}

	.ag-theme-alpine .ag-row {
		background-color: #f4f4f4;
		border: none !important;
	}

	.ag-theme-alpine .ag-paging-panel {
		border-top: none !important;
	}
}

.blue-snackbar {
	background: red;
}

.a-href {
	margin-left: 3px;
	margin-right: 3px;
}

mat-sidenav-content {
	nav {
		border-bottom: none !important;
	}
}

.short-dialog .mat-dialog-container {
	padding: 30px 20px !important;
	position: relative;
}

// apply roboto on kendo elements - make update here
button,
kendo-textbox,
k-textbox,
input {
	font-family: Gilroy, Medium !important;
}

.padded-dialog-20 .mat-dialog-container {
	padding: 20px;
	position: relative;
}

.padded-dialog-30 .mat-dialog-container {
	padding: 30px;
	position: relative;
}

.center-dialog {
	position: relative !important;
}

.center-dialog .mat-dialog-container {
	padding: 0px;
	border-radius: 10px !important;
	// position: relative;
}

.center-dialog-no-shadow {
	position: relative !important;
}

.center-dialog-no-shadow .mat-dialog-container {
	padding: 0px;
	border-radius: 10px !important;
	box-shadow: none !important;
}

.trans-back {
	position: relative !important;
}

.trans-back .mat-dialog-container {
	background-color: #00000000 !important;
	padding: 0px;
	border-radius: 10px !important;
	box-shadow: none !important;
}

.light-backdrop-blurred {
	background-color: #ffffff1a !important;
	backdrop-filter: blur(2px) !important;
}

.overflow-hidden-dialog {
}

.overflow-hidden-dialog .mat-dialog-container {
	overflow: hidden;
}

.sharp-dialog-no-shadow {
	position: relative !important;
}

.sharp-dialog-no-shadow .mat-dialog-container {
	padding: 0px;
	box-shadow: none !important;
	border-radius: 0 !important;
}

.mat-ripple {
	display: none;
}

.add-creators-to-campaign-dialog {
	position: fixed !important;
	bottom: 0;
	right: 0;
	margin-right: 255px;
	margin-bottom: 90px;
}

.add-creators-to-campaign-dialog-less-margin {
	margin-right: 100px !important;
}

.add-creators-to-campaign-dialog .mat-dialog-container {
	padding: 0px;
	border-radius: 10px !important;
	box-shadow: 0px 5px 24px #00000012 !important;
	border: 1px solid #e3e8ef !important;
	overflow: hidden;
}

.add-creators-to-list-dialog {
	position: fixed !important;
	bottom: 0;
	right: 0;
	margin-right: 80px;
	margin-bottom: 90px;
}

.add-creators-to-list-dialog .mat-dialog-container {
	padding: 0px;
	border-radius: 10px !important;
	box-shadow: 0px 5px 24px #00000012 !important;
	border: 1px solid #e3e8ef !important;
	overflow: hidden;
}

.light-backdrop {
	background: #121212;
	opacity: 0.2 !important;
}

.light-backdrop-darker {
	background: #12121275;
}

.transparent-backdrop {
	background: #000000;
	opacity: 0 !important;
}

.d-hidden {
	display: none;
}

.w-100 {
	width: 100%;
}

.h-100 {
	height: 100%;
}

.resize-both {
	resize: both !important;
}

.d-flex-column {
	display: flex;
	flex-direction: column;
}

.flex-direction-column {
	flex-direction: column;
}

// ! Custom Radio Button black
.black-radio-button {
	display: flex !important;
	align-items: center !important;
	width: 100% !important;

	.mat-radio-label {
		height: 48px !important;
		padding: 0 14px !important;
	}

	.mat-radio-label-content {
		font-weight: 600 !important;
		line-height: 1 !important;
		font-size: 14px !important;
		letter-spacing: 0px !important;
		color: #4a4a4a !important;
		display: flex !important;
		align-items: center !important;
		gap: 8px !important;
		padding-left: 14px !important;
	}

	&.h-unset {
		.mat-radio-label {
			height: unset !important;
		}
	}

	&.mat-accent.mat-radio-checked .mat-radio-outer-circle {
		border-color: #4a4a4a !important;
	}

	&.mat-accent .mat-radio-inner-circle {
		color: #4a4a4a !important;
		background-color: #4a4a4a !important;
	}

	&.mat-accent .mat-radio-ripple .mat-ripple-element {
		background-color: #4a4a4a !important;
	}
}

// ! Custom checkbox black
.custom-checkbox-black {
	display: flex !important;
	width: 100%;

	--check-color-text: #4a4a4a;
	--check-color-dark: #4a4a4a;
	--check-color-light: #c8ced1;

	&.blue {
		--check-color-dark: #1a73e9;
	}

	& .mat-checkbox-background,
	.mat-checkbox-frame {
		border-radius: 5px !important;
		border-color: var(--check-color-light) !important;
	}

	&.mat-checkbox-checked .mat-checkbox-checkmark {
		transform: scale(0.9) !important;
	}

	.mat-checkbox-inner-container {
		height: 19px !important;
		margin-right: 10px !important;
		width: 19px !important;
	}

	&.mat-checkbox-ripple .mat-ripple-element,
	&.mat-checkbox-checked.mat-accent .mat-checkbox-background,
	&.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
		background-color: var(--check-color-dark) !important;
	}

	&.mat-checkbox-checked.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
	}

	&.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
	}

	.mat-checkbox-layout,
	.mat-checkbox-label {
		width: 100% !important;
		font-weight: 500 !important;
		font-size: 14px !important;
		font-family: 'Gilroy' !important;
		letter-spacing: 0.14px !important;
		color: var(--check-color-text) !important;
	}

	.mat-checkbox-label {
		width: 100% !important;
		overflow: hidden !important;
		height: 40px !important;
		display: flex !important;
		align-items: center !important;
		white-space: normal !important;
	}
}

.custom-checkbox-black-shorter {
	display: flex !important;

	& .mat-checkbox-background,
	.mat-checkbox-frame {
		border-radius: 5px !important;
		border-color: #c8ced1 !important;
	}

	&.mat-checkbox-checked .mat-checkbox-checkmark {
		transform: scale(0.9) !important;
	}

	.mat-checkbox-inner-container {
		height: 19px !important;
		// margin-right: 10px !important;
		width: 19px !important;
	}

	&.mat-checkbox-ripple .mat-ripple-element,
	&.mat-checkbox-checked.mat-accent .mat-checkbox-background,
	&.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
		background-color: #4a4a4a !important;
	}

	&.mat-checkbox-checked.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
	}

	&.mat-accent.mat-checkbox-disabled {
		opacity: 0.4 !important;
		pointer-events: none;
	}

	.mat-checkbox-label {
		display: none !important;
	}
}

.primary-btn-01 {
	outline: none;
	border: none;
	background: #2585fe;
	border-radius: 5px;
	height: 48px;
	padding: 0px 60px;
	font-family: 'Gilroy';
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.14px;
	color: #ffffff;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		-webkit-transition: background-color 200ms;
		-ms-transition: background-color 200ms;
		transition: background-color 200ms;
		background: #ffffff;
		outline: 1px solid #2585fe;
		color: #2585fe;
	}
}

.secondary-btn-01 {
	border: none;
	background: #ffffff;
	outline: 1px solid #2585fe;
	border-radius: 5px;
	height: 48px;
	padding: 0px 60px;
	font-family: 'Gilroy';
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.14px;
	color: #2585fe;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		outline: none;
		-webkit-transition: background-color 200ms;
		-ms-transition: background-color 200ms;
		transition: background-color 200ms;
		background: #2585fe;
		color: #ffffff;
	}
}

@media screen and (min-width: 1367px) and (max-width: 1919px) {
	.graph-dialog-container {
		transform: scale(1) !important;

		mat-dialog-container {
			overflow: hidden !important;
		}
	}

	.primary-btn-01 {
		height: 40px;
		padding: 0px 50px;
		font-size: 12px;
	}

	.secondary-btn-01 {
		height: 40px;
		padding: 0px 50px;
		font-size: 12px;
	}
}

@media screen and (min-width: 0px) and (max-width: $tertiary) {
	.graph-dialog-container {
		transform: scale(1) !important;

		mat-dialog-container {
			overflow: hidden !important;
		}
	}
}

@include res-primary {
	.mat-tab-list {
		margin-right: -23px;

		.select-view {
			font-size: 14px;
		}

		app-calendar {
			.date-info {
				font-size: 14px;
			}
		}
	}
}

@include res-secondary {
	.mat-tab-list {
		margin-right: -23px;

		.select-view {
			font-size: 12px;
		}

		app-calendar {
			.date-info {
				font-size: 12px;
			}
		}
	}
}

@include res-tertiary {
	.mat-tab-list {
		.select-view {
			font-size: 12px;
		}

		app-calendar {
			.date-info {
				font-size: 12px;
			}
		}
	}
}

.mat-flat-blue-button {
	.mat-flat-button {
		background-color: #2585fe;
		color: white;
		font-family: Gilroy, Medium !important;
		padding: 6px 39px;
	}

	.mat-flat-button:hover {
		text-decoration: none !important;
		background-color: #2a87fbe6 !important;
	}

	.mat-flat-button:focus {
		text-decoration: none !important;
		background-color: #2a87fbe6 !important;
	}
}

.mat-flat-blue-button-small {
	.mat-flat-button {
		background-color: #2585fe;
		color: white;
		font-family: Gilroy, Medium !important;
		padding: 0px 20px;
	}

	.mat-flat-button:hover {
		text-decoration: none !important;
		background-color: #2a87fbe6 !important;
	}

	.mat-flat-button:focus {
		text-decoration: none !important;
		background-color: #2a87fbe6 !important;
	}
}

.mat-stroked-blue-button {
	.mat-stroked-button {
		background-color: white;
		color: #2585fe;
		border: 1px solid #2585fe;
		font-family: Gilroy, Medium !important;
		padding: 6px 41px;
	}

	.mat-stroked-button:hover {
		text-decoration: none !important;
		background-color: white !important;
	}

	.mat-stroked-button:focus {
		text-decoration: none !important;
		background-color: white !important;
	}
}

.mat-stroked-black-button {
	.mat-stroked-button {
		background-color: white;
		color: black;
		border: 1px solid black;
		font-family: Gilroy, Medium !important;
		padding: 6px 41px;
	}

	.mat-stroked-button:hover {
		text-decoration: none !important;
		background-color: white !important;
	}

	.mat-stroked-button:focus {
		text-decoration: none !important;
		background-color: white !important;
	}
}

.mat-button-blue {
	.mat-button {
		color: #2585fe;
		text-transform: none;
	}
}

.new-custom-paginator.mat-paginator {
	font-weight: 500 !important;
	font-family: 'Gilroy' !important;
	color: #4a4a4a !important;
	background-color: #00000000 !important;

	.mat-paginator-page-size-select.mat-form-field-appearance-outline {
		margin: 0 0 0 5px;
	}

	.mat-form-field-appearance-outline .mat-form-field-wrapper {
		margin: 0;
		padding: 0;
	}

	.mat-select-value-text {
		font-size: 12px;
		font-weight: 500;
		font-family: 'Gilroy' !important;
		letter-spacing: 0px;
		color: #4a4a4a;
	}

	.mat-paginator-page-size {
		display: flex;
		align-items: center;
	}

	.mat-form-field-appearance-outline .mat-form-field-infix {
		display: flex;
		align-items: center;
		padding: 0.5em 0 1em 0;
	}

	.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
		transform: translateY(0%);
	}

	.mat-icon-button,
	.mat-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mat-select-value {
		color: #4a4a4a !important;
	}

	.mat-select-arrow {
		color: #4a4a4a !important;
	}

	.mat-option {
		color: #4a4a4a !important;
	}

	.mat-option-text {
		color: #4a4a4a !important;
	}
}

.custom-blue-slide-toggle {
	.mat-slide-toggle-thumb-container {
		top: 0px;
		display: flex;
		align-items: center;
	}

	.mat-slide-toggle-thumb {
		background-color: white !important;
		transform: scale(0.8);
	}

	.mat-slide-toggle-content {
		font-size: 14px !important;
		font-family: 'Gilroy' !important;
	}

	.mat-slide-toggle-label > div {
		height: 20px;
		border-radius: 12px;
	}

	.mat-slide-toggle-bar {
		margin-right: 16px;
	}

	&.mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-bar {
		background-color: #d9d9d9;
	}

	&.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
		background-color: #1a73e9;
		height: 20px;
		border-radius: 12px;
	}
}

.custom-logo-input-wrapper {
	border-radius: 8px;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0.12);
	display: flex;
	justify-content: space-between;
	height: 48px;
	padding: 0 14px;
	align-items: center;
	gap: 8px;

	.uploaded-logo {
		min-width: 41px;
		max-width: 48px;
		height: 41px;
		overflow: hidden;
	}

	.upload-icon {
		height: 24px;
		width: 24px;
	}

	.txt-logo {
		color: #4a4a4a;
		font-size: 14px;
		font-weight: normal;
	}
}

.custom-input-name {
	width: 100%;
	border: 1px solid #4a4a4a66;
	height: 42px;
	padding: 16px;
	border-radius: 5px;
	letter-spacing: 0.14px;
	color: #4a4a4a;
	font-weight: 500;
	font-size: 14px;

	&::placeholder {
		color: #4a4a4a99;
		font-weight: normal;
	}

	&:focus {
		border: 1px solid #121212 !important;
	}
}

.grey-search {
	background: #f5f4f7;
	border-radius: 5px;
	min-height: 48px;
	height: 48px;
	padding: 0 16px;
	display: flex;
	align-items: center;
	gap: 7px;
	width: 100%;

	img {
		height: 15px;
		width: 15px;
	}

	input {
		border: none;
		font-family: 'Gilroy' !important;
		line-height: 1;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 0.14px;
		color: #4a4a4a;
		background-color: #00000000;
		width: 100%;

		&::placeholder {
			color: #cbc8d1;
		}
	}
}

@mixin custom-radio() {
	$color: #4a4a4a;

	.custom-radio-btn {
		cursor: pointer;

		input[type='radio'],
		input[type='checkbox'] {
			display: none;
		}

		input[type='radio'] + span,
		input[type='checkbox'] + span {
			display: inline-block;
			position: relative;
			margin-left: 30px;
		}

		input[type='radio'] + span:before,
		input[type='checkbox'] + span:before {
			content: '';
			position: absolute;
			width: 20px;
			height: 20px;
			background-color: #fff;
			left: -30px;
			top: -2px;
			border: 1px solid $color;
		}

		input[type='radio'] + span:before {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			-khtml-border-radius: 50%;
		}

		input[type='radio']:checked + span:after,
		input[type='checkbox']:checked + span:after {
			content: '';
			position: absolute;
			width: 12px;
			height: 12px;
			background-color: $color;
			left: -26px;
			top: 2px;
			border: 1px solid $color;
		}

		input[type='radio']:checked + span:after {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			-khtml-border-radius: 50%;
		}
	}
}

@include custom-radio();

.mat-menu-panel.template-menu {
	background: #ffffff !important;
	box-shadow: 0px 4px 8px #4a4a4a24 !important;
	border-radius: 5px !important;
	width: calc(590px - 10px) !important;
	max-width: calc(590px - 10px) !important;
	height: 368px !important;
	max-height: 368px !important;
	padding: 0 !important;

	.mat-menu-content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.body {
		padding: 10px;
		overflow: auto;
		flex: 1;
		padding-bottom: 0;

		.list-item.custom-template {
			display: flex !important;
			flex-direction: column;
			gap: 8px !important;
			padding: 12px !important;
			font-family: 'Gilroy' !important;
			cursor: pointer;

			&:hover {
				background: #f7f7f7 !important;
				border-radius: 5px !important;

				.name {
					color: #005ed8;
				}

				.name .icons {
					visibility: visible;
				}
			}

			.name {
				font: normal normal 600 14px / normal Gilroy;
				letter-spacing: 0.14px;
				display: flex;
				justify-content: space-between;

				.icons {
					display: flex;
					align-items: center;
					gap: 16px;
					visibility: hidden;

					.star {
						height: 17px;
						width: 17px;
					}
				}
			}

			.title {
				font: normal normal 600 12px / normal Gilroy;
				letter-spacing: 0.12px;
				color: #616161;
			}

			.desc {
				font: normal normal 500 12px / normal Gilroy;
				letter-spacing: 0.12px;
				color: #878787;

				img {
					display: none !important;
				}
			}

			.name,
			.title,
			.desc {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.footer-wrapper {
		background: transparent;

		.shadow {
			background: transparent
				linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0%
				no-repeat padding-box;
			opacity: 0.04;
			width: 100%;
			height: 28px;
		}

		.footer {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 11px;
			background: #fff;

			button {
				&:hover {
					img {
						filter: invert(100%) sepia(100%) saturate(16%)
							hue-rotate(289deg) brightness(105%) contrast(103%);
					}
				}
			}

			&.end {
				justify-content: end;
			}
		}
	}
}

// !default card ui
.card {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 1px 2px 6px #12121229;
	border-radius: 5px;
	overflow: hidden;
}

// ! Website Menu
.website-menu {
	background: #ffffff !important;
	border: 1px solid #e3e8ef !important;
	box-shadow: 0px 5px 24px 0px #00000012 !important;
	border-radius: 5px !important;
	max-width: unset !important;
	min-width: 300px !important;
	width: 300px !important;
	max-height: 386px !important;

	.filter-menu-list-item {
		width: 100%;
		height: 50px !important;
		padding: 0px 10px !important;
		color: #4a4a4a !important;
		font-weight: 500 !important;
		line-height: 24px !important;
		font-family: 'Gilroy' !important;

		display: flex !important;
		align-items: center !important;
		gap: 10px !important;
		margin: 0 !important;
		cursor: pointer !important;

		&.selected {
			background-color: #f7f7f7 !important;
		}

		&.disabled {
			opacity: 0.6 !important;
			pointer-events: none !important;
		}

		&:hover {
			background: #f7f7f7 !important;
		}

		img {
			height: 15px;
			width: 15px;
		}

		.filter-drp-name {
			font-size: 14px !important;
			line-height: 24px !important;
			letter-spacing: 0.14px !important;
			font-weight: 500 !important;
			font-family: 'Gilroy' !important;
			white-space: normal !important;
			cursor: pointer;
		}
	}
}

// ! Filter Menu Without Sort
.options-menu {
	background: #ffffff !important;
	box-shadow: 0px 3px 16px #0000001a !important;
	border-radius: 10px !important;
	max-width: unset !important;
	min-width: calc(100% + 36px) !important;
	// min-width: 196px !important;
	// width: 196px !important;
	max-height: 386px !important;
	padding: 10px 0px !important;

	.filter-menu-list-item {
		display: flex !important;
		align-items: center !important;
		gap: 10px !important;
		padding: 10px 20px !important;
		margin: 0 !important;
		font-family: 'Gilroy' !important;
		cursor: pointer !important;

		&.disabled {
			opacity: 0.6 !important;
			pointer-events: none !important;
		}

		&:hover {
			background: #f7f7f7 !important;
			border-radius: 5px !important;
		}

		img {
			height: 15px;
			width: 15px;
		}

		.filter-drp-name {
			letter-spacing: 0.14px !important;
			font-weight: 500 !important;
			font-size: 14px !important;
			line-height: 20px !important;
			color: #0b0b0b !important;
			font-family: 'Gilroy' !important;
			white-space: normal !important;
			cursor: pointer;
		}
	}
}

// ! DRP BTN MENU
.drp-btn-menu {
	margin-top: 4px !important;
	background: #fff !important;
	box-shadow: none !important;
	border: 1px solid #4a4a4a !important;
	border-radius: 5px !important;
	max-width: unset !important;
	min-width: fit-content !important;
	width: fit-content !important;
	max-height: 386px !important;
	padding: 0px !important;

	@media only screen and (max-height: 800px) {
		margin-top: 0 !important;
	}

	.drp-btn-list-item {
		height: 42px !important;
		padding: 0px 16px !important;
		color: #4a4a4a !important;
		font-weight: 600 !important;
		font-size: 14px !important;
		font-family: 'Gilroy' !important;
		letter-spacing: 0.14px !important;
		//  !
		display: flex !important;
		align-items: center !important;
		gap: 10px !important;
		margin: 0 !important;
		cursor: pointer !important;

		&:hover {
			background: #4a4a4a !important;
			color: #fff !important;
		}
	}
}

.currency-menu {
	background: #ffffff !important;
	border: 1px solid #e3e8ef;
	box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.07);
	border-radius: 10px !important;
	max-width: unset !important;
	min-width: 76px !important;
	width: 76px !important;
	max-height: 200px !important;
	padding: 0px !important;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	.currency-menu-list-item {
		display: flex !important;
		align-items: center !important;
		gap: 10px !important;
		padding: 17px 12px 17px 12px !important;
		margin: 0 !important;
		font-family: 'Gilroy' !important;
		cursor: pointer !important;

		&:hover {
			background: #f7f7f7 !important;
			border-radius: 5px !important;
		}

		.currency-drp-name {
			letter-spacing: 0.14px !important;
			font-weight: 500 !important;
			font-size: 14px !important;
			line-height: normal !important;
			color: #0b0b0b !important;
			font-family: 'Gilroy' !important;
			white-space: normal !important;
			cursor: pointer;
		}

		&.selected {
			background: #f7f7f7 !important;
		}
	}
}

// ! STYLES FOR SOCIAL MEDIA TABS
.social-tabs-wrapper {
	width: min-content;
	align-items: center;
	display: flex;
	background-color: #f5f4f7;
	height: 48px;
	border-radius: 5px;
	position: relative;
	padding: 3px;

	.white-slab {
		width: 42px;
		height: 42px;
		position: absolute;
		top: 0;
		left: 0;
		background: #ffffff;
		box-shadow: 1px 2px 6px #12121229;
		border-radius: 3px;
		margin-top: 3px;
		margin-left: 3px;
		transition: 0.3s all;
		z-index: 2;

		&.middle {
			margin-left: 45px;
		}

		&.right {
			margin-left: 88px;
		}
	}

	.tab {
		width: 42px;
		background: #ffffff00;
		height: 42px;
		font-family: 'Gilroy';
		line-height: 1;
		font-weight: 600;
		font-size: 14px;
		color: #4a4a4a;
		letter-spacing: 0.14px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		border-radius: 3px;
		cursor: pointer;

		img,
		.text {
			z-index: 3;
		}

		&.disable {
			img,
			.text {
				opacity: 50%;
			}
		}

		&:hover {
			background: #fff;
		}

		img {
			height: 15px;
			width: 15px;
		}
	}
}

// ! STYLES FOR BOTTOM INDICATOR TAB BAR
.bottom-indicator-tab-bar {
	width: 220px;
	align-items: center;
	display: flex;
	height: 48px;
	position: relative;
	padding: 3px;
	z-index: 1;

	.bottom-indicator {
		width: 110px;
		height: 2px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: #4a4a4a;
		border-radius: 3px;
		transition: 0.4s all;
		z-index: 2;

		&.right {
			margin-left: 110px;
		}
	}

	.tab {
		width: 107px;
		background: #ffffff00;
		height: 42px;
		font-family: 'Gilroy';
		line-height: 1;
		font-weight: 600;
		font-size: 14px;
		color: #4a4a4a;
		letter-spacing: 0.14px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 3;
	}
}

// ! CUSTOM DESC INPUT
.custom-desc-input {
	width: 100%;
	height: 42px;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: 0em;
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	background: #fff;
	color: #121212;
	padding: 0 10px;

	&::placeholder {
		color: #ababab;
	}
}

// ! CUSTOM DESC TEXTAREA
.custom-desc-textarea {
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: 0em;
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	background: #fff;
	font-family: 'Gilroy' !important;
	color: #121212;
	padding: 10px 13px;
	resize: none;

	&::placeholder {
		color: #ababab;
	}
}

.overflow-ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.flex-1 {
	flex: 1;
}

// ! Heading Chips Wrapper
.heading-chips-wrapper {
	display: flex;
	align-items: center;
	gap: 16px;

	.single-chip {
		height: 36px;
		padding-inline: 14px;
		border-radius: 37px;
		outline: 1px solid #1212124d;
		display: flex;
		align-items: center;
		cursor: pointer;
		color: #121212;
		font-size: 14px;
		font-weight: 500;
		background-color: #ffffff;
		gap: 10px;
		transition: 0.3s all;

		img {
			height: 16px;
			width: 16px;
		}

		&.selected {
			color: #ffffff;
			background-color: #1a73e9;
			outline: none;

			img {
				filter: invert(1);
			}
		}
	}
}

// ! Reporting Cards Wrapper
.reporting-cards-wrapper {
	width: 100%;
	display: flex;
	align-items: center;

	.card-list {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 56px;

		.summary-card {
			width: 100%;
			height: 95px;
			border-radius: 5px;
			box-shadow: 1px 2px 6px 0px #12121229;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-inline: 20px;

			&.orange {
				border-left: 4px solid #ffe1c4;

				.circle-img {
					background-color: #ffe1c4;
				}
			}

			&.green {
				border-left: 4px solid #d5f5e1;

				.circle-img {
					background-color: #d5f5e1;
				}
			}

			&.blue {
				border-left: 4px solid #dff3ff;

				.circle-img {
					background-color: #dff3ff;
				}
			}

			&.pink {
				border-left: 4px solid #ffdaec;

				.circle-img {
					background-color: #ffdaec;
				}
			}

			.circle-img {
				height: 40px;
				width: 40px;
				min-width: 40px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 24px;
					width: 24px;
				}
			}

			.label-col {
				display: flex;
				flex-direction: column;
				align-items: end;
				gap: 4px;
				flex: 1;
				overflow: hidden;

				label {
					color: #878787;
					font-size: 14px !important;
					font-weight: 500 !important;
					line-height: 21px;
				}

				.value {
					color: #121212;
					font-size: 20px !important;
					font-weight: 700 !important;
					line-height: normal;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 90%;
				}
			}
		}
	}
}

.grey-chips-wrapper {
	display: flex;
	align-items: center;
	gap: 10px;

	.grey-chip {
		height: 24px;
		padding-inline: 8px;
		min-height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		border: 1px solid #d5d5d5;
		background: #1212120f;
		color: #75787b;
		font-size: 12px;
		font-weight: 600;
	}
}

// ! Custom Mat Slide Toggle
.custom-slide-toggle {
	.mat-slide-toggle-thumb {
		width: 12px !important;
		height: 12px !important;
		transform: translate(50%, 50%);
		background-color: #fff;
	}

	.mat-slide-toggle-bar {
		background-color: #d9d9d9;
		border-radius: 16px !important;
		height: 16px !important;
	}

	.mat-slide-toggle-content {
		color: #4a4a4a;
		font-family: 'Gilroy';
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0.16px;
	}

	&.mat-checked .mat-slide-toggle-bar {
		background-color: #1a73e9 !important;
	}

	&.mat-checked .mat-slide-toggle-thumb {
		background-color: #ffffff !important;
	}

	.mat-slide-toggle-thumb-container {
		top: -4px !important;
		left: -3px !important;
	}
}

//! Onboarding Dialog
.onboarding-dialog-no-shadow {
	position: fixed !important;
	bottom: 0;
	left: 0;
	margin-left: 100px;
	margin-bottom: 10px;
}

.onboarding-dialog-no-shadow .mat-dialog-container {
	padding: 0px;
	border-radius: 10px !important;
	box-shadow: none !important;
}

.files-menu {
	background: #fff !important;
	box-shadow: 0px 3px 16px #0000001a !important;
	border-radius: 10px !important;
	max-width: unset !important;
	min-width: 196px !important;
	width: 196px !important;
	max-height: 386px !important;
	padding: 10px 0px !important;

	.file-menu-list-item {
		display: flex !important;
		align-items: center !important;
		gap: 10px !important;
		padding: 10px 20px !important;
		margin: 0 !important;
		font-family: 'Gilroy' !important;
		cursor: pointer !important;

		&:hover {
			background: #f7f7f7 !important;
			border-radius: 5px !important;
		}

		.file {
			flex: 1 !important;
			overflow: hidden !important;
			text-overflow: ellipsis !important;
			white-space: nowrap !important;
		}
	}
}

.lolly-verified-symbol {
	width: 17px;
	height: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: #8ed77e;
	border-radius: 50%;

	mat-icon {
		transform: scale(0.5);
		color: #ffffff;
		font-weight: bold;
	}
}

// Filter Menu Without Sort
.filter-menu {
	background: #ffffff !important;
	box-shadow: 0px 3px 16px #0000001a !important;
	border-radius: 10px !important;
	max-width: unset !important;
	min-width: 226px !important;
	width: 226px !important;
	max-height: 386px !important;
	padding: 0px !important;
	display: flex !important;
	flex-direction: column !important;
	overflow: hidden !important;

	.mat-menu-content {
		display: flex !important;
		flex-direction: column !important;
		height: 100% !important;
	}

	.menu-header {
		padding: 20px 20px 10px 20px !important;
		font-family: 'Gilroy' !important;
		letter-spacing: 0px !important;
		color: #878787 !important;
		font-size: 12px !important;
		font-weight: 600 !important;
	}

	.menu-body {
		padding: 0 0 10px 0 !important;
		flex: 1 !important;
		overflow-y: auto !important;

		.filter-menu-list-item {
			display: flex !important;
			align-items: center !important;
			gap: 12px !important;
			padding: 0px 20px !important;
			margin: 0;
			font-family: 'Gilroy' !important;

			&:hover {
				background: #f7f7f7 !important;
				border-radius: 5px !important;
			}

			.filter-drp-name {
				letter-spacing: 0.14px !important;
				font-weight: 500 !important;
				font-size: 14px !important;
				line-height: 20px !important;
				color: #0b0b0b !important;
				font-family: 'Gilroy' !important;
				white-space: normal !important;
				cursor: pointer;
			}
		}
	}

	.menu-footer {
		padding: 10px 20px 20px 20px !important;
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;

		.primary-btn {
			height: 38px !important;
		}

		.quaternary-btn {
			height: 38px !important;
		}
	}
}

// Filter Menu With Sort
.filter-sort-menu {
	background: #ffffff !important;
	box-shadow: 0px 3px 16px #0000001a !important;
	border-radius: 10px !important;
	max-width: unset !important;
	min-width: 226px !important;
	width: 226px !important;
	max-height: 386px !important;
	padding: 0px !important;
	display: flex !important;
	flex-direction: column !important;
	overflow: hidden !important;

	.mat-menu-content {
		display: flex !important;
		flex-direction: column !important;
		height: 100% !important;
	}

	.menu-title {
		padding: 20px 20px 10px 20px !important;
		font-family: 'Gilroy' !important;
		letter-spacing: 0px !important;
		color: #878787 !important;
		font-size: 12px !important;
		font-weight: 600 !important;
	}

	.menu-body {
		padding: 0 0 10px 0 !important;
		flex: 1 !important;
		overflow-y: auto !important;

		.grey-search {
			background: #f5f4f7;
			border-radius: 5px;
			height: 48px;
			padding: 0 16px;
			// margin-inline: 20px;
			display: flex;
			align-items: center;
			gap: 7px;

			img {
				height: 15px;
				width: 15px;
			}

			input {
				border: none;
				font-family: 'Gilroy' !important;
				line-height: 1;
				font-weight: 600;
				font-size: 14px;
				letter-spacing: 0.14px;
				color: #4a4a4a;
				background-color: #00000000;
				width: 100%;

				&::placeholder {
					color: #cbc8d1;
				}
			}
		}

		.filter-menu-list-item {
			display: flex !important;
			align-items: center !important;
			gap: 12px !important;
			padding: 0px 20px !important;
			margin: 0;
			font-family: 'Gilroy' !important;

			&:hover {
				background: #f7f7f7 !important;
				border-radius: 5px !important;
			}

			.filter-drp-name {
				letter-spacing: 0.14px !important;
				font-weight: 500 !important;
				font-size: 14px !important;
				line-height: 20px !important;
				color: #0b0b0b !important;
				font-family: 'Gilroy' !important;
				white-space: normal !important;
				cursor: pointer;
			}

			&.v-pad {
				padding: 8px 20px !important;
			}

			&.dark {
				background: #efeeee !important;
			}
		}
	}

	.menu-footer {
		padding: 10px 20px 20px 20px !important;
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;

		.primary-btn {
			height: 38px !important;
		}

		.quaternary-btn {
			height: 38px !important;
		}
	}
}

// FILTER SORT MENU LONG
.filter-sort-menu-long {
	background: #ffffff !important;
	box-shadow: 0px 3px 16px #0000001a !important;
	border-radius: 10px !important;
	max-width: unset !important;
	min-width: 226px !important;
	width: 226px !important;
	max-height: 506px !important;
	padding: 0px !important;
	display: flex !important;
	flex-direction: column !important;
	overflow: hidden !important;

	.mat-menu-content {
		display: flex !important;
		flex-direction: column !important;
		height: 100% !important;
	}

	.menu-title {
		padding: 20px 20px 10px 20px !important;
		font-family: 'Gilroy' !important;
		letter-spacing: 0px !important;
		color: #878787 !important;
		font-size: 12px !important;
		font-weight: 600 !important;
	}

	.menu-body {
		padding: 0 0 10px 0 !important;
		flex: 1 !important;
		overflow-y: auto !important;

		.grey-search {
			background: #f5f4f7;
			width: unset !important;
			border-radius: 5px;
			height: 48px;
			padding: 0 16px;
			margin-inline: 20px;
			display: flex;
			align-items: center;
			gap: 7px;

			img {
				height: 15px;
				width: 15px;
			}

			input {
				border: none;
				font-family: 'Gilroy' !important;
				line-height: 1;
				font-weight: 600;
				font-size: 14px;
				letter-spacing: 0.14px;
				color: #4a4a4a;
				background-color: #00000000;
				width: 100%;

				&::placeholder {
					color: #cbc8d1;
				}
			}
		}

		.filter-menu-list-item {
			display: flex !important;
			align-items: center !important;
			gap: 12px !important;
			padding: 0px 20px !important;
			margin: 0;
			font-family: 'Gilroy' !important;

			&:hover {
				background: #f7f7f7 !important;
				border-radius: 5px !important;
			}

			.filter-drp-name {
				letter-spacing: 0.14px !important;
				font-weight: 500 !important;
				font-size: 14px !important;
				line-height: 20px !important;
				color: #0b0b0b !important;
				font-family: 'Gilroy' !important;
				white-space: normal !important;
				cursor: pointer;
			}

			&.v-pad {
				padding: 8px 20px !important;
			}

			&.dark {
				background: #efeeee !important;
			}
		}

		.filter-col {
			padding-top: 15px !important;
			padding-inline: 20px !important;
			display: flex !important;
			flex-direction: column !important;
			gap: 12px !important;

			small {
				font-family: 'Gilroy' !important;
				letter-spacing: 0px !important;
				font-weight: 500 !important;
				font-size: 14px !important;
				color: #4a4a4a !important;
			}
		}
	}

	.menu-footer {
		padding: 10px 20px 20px 20px !important;
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;

		.primary-btn {
			height: 38px !important;
		}

		.quaternary-btn {
			height: 38px !important;
		}
	}
}

.open-in-new-tab {
	position: absolute;
	top: 0;
	right: 0;
	margin: 10px;
	cursor: pointer;
	color: white;
	transform: scale(0.9);
	text-shadow: 0px 0px 5px #00000096;
}

.money-input-wrapper {
	display: flex;
	align-items: center;
	height: 42px;
	width: 100%;
	border: 1px solid #4a4a4a66;
	padding: 0px 10px;
	border-radius: 5px;

	.prefix-symbol {
		color: #4a4a4acc;
		pointer-events: none;
		font-weight: 500;
		font-size: 14px;
	}

	.inner-input {
		width: 100%;
		letter-spacing: 0.14px;
		color: #4a4a4a;
		height: 100%;
		font-weight: 500;
		font-size: 14px;
		padding-left: 6px;
		padding-right: 10px;
		border: none;

		&::placeholder {
			color: #4a4a4a66;
		}
	}
}

.pagination-wrapper {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: end;
	height: 60px;
	box-shadow: 1px 2px 6px #12121229;
	border-radius: 5px;
	padding-right: 22px;

	&.mt-extra {
		margin-top: 28px;
	}
}

.chart-type-switcher {
	display: flex;
	align-items: center;
	width: 88px;
	height: 28px;
	border-radius: 4px;
	border: 1px solid #ebebeb;
	background: #fff;

	.icon-wrapper {
		height: 100%;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&.selected {
			mat-icon {
				color: #005ed8;
			}
		}

		mat-icon {
			color: #7a7a7a;
			transform: scale(0.9);
			transition: color 0.1s;
		}
	}

	.br-right {
		border-right: 1px solid #ebebeb;
	}
}

.custom-blue-tab-switcher {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	background: #fff;
	gap: 4px;

	.custom-tab {
		flex: 1;
		height: 100%;
		cursor: pointer;
		transition: all 0.3s;
		padding: 4px 4px 4px 8px;
		border-radius: 2px;

		&:not(:first-of-type) {
			border-left: 1px solid #ebebeb;
		}

		&.selected {
			background-color: #e9f1fc;

			.title {
				color: #005ed8;

				&.bigger {
					color: #005ed8;
				}
			}
		}

		.title {
			color: #7a7a7a;
			font-size: 14px;
			font-weight: 600;
			line-height: normal;

			&.bigger {
				color: #4a4a4a;
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
}

.nav-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #e3e8ef;
	width: 48px;
	height: 48px;
	background: #fff;
	cursor: pointer;

	&:hover {
		border: 1px solid #005ed8;

		img {
			filter: invert(24%) sepia(39%) saturate(5027%) hue-rotate(204deg)
				brightness(95%) contrast(104%);
		}
	}
}

.hoverable-btn {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 48px;
	padding-inline: 16px;
	gap: 5px;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	line-height: normal;
	cursor: pointer;
	background-color: #ffffff;
	border: 1px solid #4a4a4a;
	color: #4a4a4a;
	transition: 0.2s all;
	border-radius: 4px;
	min-width: 90px;

	&:hover {
		background-color: #4a4a4a;
		color: #ffffff;

		img {
			filter: invert(1) brightness(2);
		}
	}

	img {
		height: 13px;
		width: 13px;
		filter: brightness(0.5);
	}
}

// ! Toggle Column Menu
.toggle-column-menu {
	background: #ffffff !important;
	box-shadow: 0px 3px 16px #0000001a !important;
	border-radius: 10px !important;
	max-width: unset !important;
	min-width: 250px !important;
	width: 250px !important;
	max-height: 386px !important;
	padding: 20px 0px !important;
	position: relative;

	.abs-top-right {
		position: absolute;
		top: 0;
		right: 0;
		margin: 10px;
		cursor: pointer;
	}

	.slider-wrapper {
		display: flex !important;
		align-items: center !important;
		padding: 10px 24px !important;
		margin: 0 !important;
	}
}

.error-msg {
	color: $error-red-color;
	font-size: 12px;
	letter-spacing: 0.12px;
	font-weight: 400;
}

.overflow-hidden {
	overflow: hidden;
}

.w-fit-content {
	width: fit-content !important;
}

.disabled-low-opacity {
	opacity: 0.5 !important;
	pointer-events: none !important;
}

.dark-shadow-cards-wrapper {
	display: flex;
	align-items: center;
	gap: 24px;
	width: 100%;

	.dark-shadow-card-item {
		@include dark-shadow-card-mixin();
		flex: 1;
		cursor: default;
		display: flex;
		align-items: center;
		gap: 16px;
		min-height: 117px;
		height: 117px;
		padding: 0 24px;

		.icon-wrapper {
			height: 44px;
			width: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 44px;
			min-width: 44px;
			background-color: #f0faf1;
			border-radius: 50%;
			border: 1px solid #95ce98;

			&.red {
				background-color: #f8d6d6;
				border: 1px solid #fcb9b9;
			}
		}
	}
}

.dark-shadow-cards-short-wrapper {
	display: flex;
	align-items: center;
	gap: 24px;
	width: 100%;

	.dark-shadow-card-short-item {
		@include dark-shadow-card-mixin();
		flex: 1;
		cursor: default;
		display: flex;
		flex-direction: column;
		gap: 8px;
		min-height: 117px;
		height: 117px;
		padding: 12px 16px;

		.icon-wrapper {
			height: 32px;
			width: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 32px;
			min-width: 32px;
			background-color: #f0faf1;
			border-radius: 50%;
			border: 1px solid #95ce98;

			img {
				height: 16px;
				width: 16px;
			}

			&.red {
				background-color: #f8d6d6;
				border: 1px solid #fcb9b9;
			}

			&.orange {
				background-color: #ffebd9;
				border: 1px solid #ffc185;
			}

			&.blue {
				background-color: #d3e3f8;
				border: 1px solid #6dabfc;
			}
		}
	}
}

.toggle-btns-wrapper {
	border: 1px solid #ebebeb;
	display: flex;
	align-items: center;
	border-radius: 4px;
	overflow: hidden;

	.toggle-btn {
		border-right: 1px solid #ebebeb;
	}
}
