@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import '@progress/kendo-theme-bootstrap/dist/all.scss';
@import './color-palette.scss';
@import './variable.scss';
@import './typography-styles.scss';
@import './buttons.scss';
@import './inputs.scss';
@import './cards.scss';
@import './app.scss';
@import 'generic-properties';
@import 'typography';
@import 'responsiveness';
@import 'form';
@import './side-pop-ups.scss';
