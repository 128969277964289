@keyframes fade-in-right {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fade-out-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		opacity: 0;
	}
}

.slide {
	transition: all 0.3s ease-in;

	&.slideInRight {
		animation: fade-in-right 0.3s ease-in both;
	}

	&.slideOutRight {
		animation: fade-out-right 0.3s ease-in both;
	}
}

.custom-dexter-dialog {
	margin-top: 56px !important;
	animation: fade-in-right 0.3s ease-in both !important;
}

.cdk-overlay-pane.slide {
	@include media-below($breakpoint-md) {
		max-width: unset !important;
		width: 80% !important;
	}
	@include media-below($breakpoint-sm) {
		width: 100% !important;
	}
}

.generic-popup {
	@include media-below($breakpoint-sm) {
		max-width: unset !important;
		width: 90% !important;
	}
}
