// Breakpoints
// Example: breakpoint-sm is the limit between xs and sm
// Therefore, xs is below breakpoint-sm and sm is between breakpoint-sm and breakpoint-md

$breakpoint-base: 0px;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1919px;

// =============================================

@mixin media-above($breakpoint) {
	@media screen and (min-width: $breakpoint) {
		@content;
	}
}

@mixin media-below($breakpoint) {
	@media screen and (max-width: $breakpoint) {
		@content;
	}
}

// =============================================
@mixin media-between($left-breakpoint, $right-breakpoint) {
	@media screen and (min-width: $left-breakpoint) and (max-width: $right-breakpoint) {
		@content;
	}
}

// new responsive structure

$primary: 1920px;
$secondary: 1536px;
$middle: 1440px;
$tertiary: 1366px;
$lower: 1280px;

@mixin res-primary {
	@media screen and (min-width: 1920px) {
		@content;
	}
}

@mixin res-less-primary {
	@media screen and (min-width: 1280px) and (max-width: 1919px) {
		@content;
	}
}

@mixin res-less-middle {
	@media screen and (min-width: 1400px) and (max-width: 1919px) {
		@content;
	}
}

@mixin res-secondary {
	@media screen and (min-width: 1367px) and (max-width: 1919px) {
		@content;
	}
}

@mixin res-tertiary {
	@media screen and (min-width: 0px) and (max-width: $tertiary) {
		@content;
	}
}

@mixin res-lower {
	@media screen and (min-width: 0px) and (max-width: $lower) {
		@content;
	}
}
