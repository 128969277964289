// ******* BUTTONS ******** //
.primary-btn {
	@include common-button-styles();
	background-color: var(--secondary-btn-color);
	color: #ffffff;
	border: none;
	min-width: fit-content;
	gap: 6px;

	&.large {
		padding-inline: 40px;
	}

	img {
		height: 15px;
		width: 15px;
	}

	&:hover {
		background-color: var(--secondary-dark-btn-color);
		color: #ffffff;
	}

	&.disabled,
	&:disabled {
		background-color: var(--disabled-btn-color) !important;
		pointer-events: none;
	}

	&.alternate-1 {
		background-color: var(--primary-btn-color);

		&:hover {
			background-color: var(--primary-dark-btn-color);
		}
	}
}

.secondary-btn {
	@include common-button-styles();
	background-color: #ffffff;
	color: var(--secondary-btn-color);
	border: 1px solid var(--secondary-btn-color);
	gap: 8px;

	&.large {
		padding-inline: 40px;
	}

	mat-icon {
		transform: scale(0.8);
	}

	mat-icon.img-default {
		color: var(--secondary-btn-color);
	}

	mat-icon.img-hover {
		color: #fff;
	}

	img {
		height: 16px;
		width: 16px;
	}

	.img-hover {
		display: none;
	}

	&:hover {
		background-color: var(--secondary-dark-btn-color);
		color: #ffffff;

		.img-default {
			display: none !important;
		}

		.img-hover {
			display: unset !important;

			&.filtered {
				filter: invert(1) contrast(2);
			}
		}
	}

	&.disabled,
	&:disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	&.alternate-1 {
		color: var(--primary-btn-color);
		border: 1px solid var(--primary-btn-color);

		&:hover {
			color: #ffffff;
			background-color: var(--primary-btn-color);
		}
	}
}

.tertiary-btn {
	@include common-button-styles();
	padding: 0px 10px 0px 16px;
	background-color: #ffffff;
	color: var(--secondary-btn-color);
	border: 1px solid var(--secondary-btn-color);
	gap: 12px;

	.img-wrapper {
		height: 28px;
		width: 28px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 12px;
			width: 12px;
		}
	}

	&:hover {
		.img-wrapper {
			background: var(--grey-shade-5);
		}
	}

	&.disabled {
		opacity: 0.4;
		pointer-events: none;
	}
}

.quaternary-btn {
	@include common-button-styles();
	background-color: transparent;
	color: var(--secondary-dark-btn-color);
	border: none;

	&.disabled,
	&:disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	&.no-pad-right {
		padding-right: 0;
	}

	&.alternate-1 {
		color: var(--primary-btn-color);
	}
}

.delete-icon-btn {
	height: 15px;
	width: 15px;
	background-image: url('/assets/crm/templates/Icon feather-trash.svg');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	filter: invert(0.6);
	transition: 0.3s all;

	// &:hover {
	// 	background-image: url('/assets/icons/ios-delete-icon.svg');
	// // filter: invert(0%) sepia(45%) saturate(3899%) hue-rotate(346deg) brightness(88%) contrast(86%);
	// }
}

// ******** MAT ICON BUTTONS ******** //

.mat-icon-btn-dark {
	@include common-mat-icon-button-styles();
	color: var(--alternate-primary-color);
}

.mat-icon-btn-grey {
	@include common-mat-icon-button-styles();
	color: var(--grey-shade-3);
}

.mat-icon-btn-red {
	@include common-mat-icon-button-styles();
	color: var(--red-shade-1);
}

.mat-delete-close-btn {
	@include common-mat-icon-button-styles();
	color: var(--red-shade-1);
}

.mat-delete-btn {
	@include common-mat-icon-button-styles();
	color: var(--red-shade-1);
}

// ******** CHIPS ******** //

.chip-style-1 {
	height: 24px;
	padding: 3px 10px;
	background-color: #1a73e90f;
	border: 1px solid #1a73e936;
	font-size: var(--font-size-12);
	font-weight: var(--font-weight-bold);
	color: var(--primary-color);
	display: flex;
	align-items: center;
	gap: 4px;

	&.with-close {
		padding-right: 0;
	}
}

.chip-style-2 {
	height: 24px;
	padding: 3px 10px;
	background-color: #005ed81a;
	font-size: var(--font-size-12);
	font-weight: var(--font-weight-500);
	color: var(--alternate-primary-color);
	border-radius: 3px;
	display: flex;
	align-items: center;
	gap: 4px;

	&.with-close {
		padding-right: 0;
	}
}

.chip-style-3 {
	height: 24px;
	padding: 3px 10px;
	background-color: #f1f1f1;
	border: 1px solid #d5d5d5;
	font-size: var(--font-size-12);
	font-weight: var(--font-weight-500);
	color: var(--alternate-primary-color);

	&.with-close {
		padding-right: 0;
	}
}

.toggle-btn {
	padding: 0px 16px;
	min-width: 90px;
	height: 48px;
	gap: 5px;
	border: none;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 600;
	color: var(--grey-shade-4);
	cursor: pointer;
	transition: 0.3s all;

	&:hover {
		background-color: #ebebeb;
	}
}
