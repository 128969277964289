@import 'standard-colour-codes';

// ! OLD STYLES - KEPT SO EXISTING CODE DOESN'T BREAK ! //
$sidenav-width: 183px;
$radius: 6px;

$highest-z-index: 99999999999999;
$second-middle-highest-z-index: 9999999;
$second-highest-z-index: 99999;
$third-highest-z-index: 9999;
$forth-highest-z-index: 999;
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
);
$breakpoints: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);

%user-management-container {
	margin: 9vh 9vw 4vh;
	height: fit-content;
	background: #ffffff 0 0 no-repeat padding-box;
	box-shadow: 2px 2px 12px #d9d9d9;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	padding: 1.56vh 5.98vw 5vh;
	display: grid;
	position: relative;
}

// ! END OF OLD STYLES ! //

// ************* NEW STYLES ************** //

:root {
	// ******* Font Sizes ******* //
	--font-size-8: 8px;
	--font-size-10: 10px;
	--font-size-12: 12px;
	--font-size-14: 14px;
	--font-size-16: 16px;
	--font-size-18: 18px;
	--font-size-20: 20px;
	--font-size-22: 22px;
	--font-size-24: 24px;
	--font-size-26: 26px;
	--font-size-28: 28px;
	--font-size-30: 30px;
	--font-size-32: 32px;
	--font-size-34: 34px;
	--font-size-36: 36px;
	--font-size-38: 38px;
	--font-size-40: 40px;
	--font-size-42: 42px;

	// ******* Font Weights ******* //
	--font-weight-normal: normal;
	--font-weight-100: 100;
	--font-weight-200: 200;
	--font-weight-300: 300;
	--font-weight-400: 400;
	--font-weight-500: 500;
	--font-weight-600: 600;
	--font-weight-700: 700;
	--font-weight-800: 800;
	--font-weight-900: 900;
	--font-weight-bold: bold;

	// ******* Line Heights ******* //
	--line-height-normal: normal;
	--line-height-1: 1;

	// ******* Border Radius ******* //
	--border-radius-1: 1px;
	--border-radius-2: 2px;
	--border-radius-3: 3px;
	--border-radius-4: 4px;
	--border-radius-5: 5px;
	--border-radius-6: 6px;
	--border-radius-7: 7px;
	--border-radius-8: 8px;
	--border-radius-9: 9px;
	--border-radius-10: 10px;
	--border-radius-full: 50%;

	// ******* Box Shadows ******* //
	--custom-input-box-shadow: 0px 2px 6px 0px #dce1e870;

	// ******* Z-Indexes ******* //
	--highest-z-index: 99999999999999;
}

// **************** MIXINS ****************** //

@mixin restrict-user-select() {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin common-button-styles() {
	font-size: var(--font-size-14);
	font-weight: var(--font-weight-600);
	letter-spacing: 0.14px;
	border-radius: var(--border-radius-5);
	height: 42px;
	padding: 0px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	white-space: nowrap;
	transition: 0.2s all;
}

@mixin common-mat-icon-button-styles {
	@include restrict-user-select();
	cursor: pointer;
	transform: scale(0.8);
}

@mixin text-overflow-ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin dark-shadow-card-mixin() {
	border-radius: var(--border-radius-4);
	border: 1px solid var(--grey-shade-5);
	transition: 0.2s all;
	box-shadow: 1px 2px 6px 0px #12121229;
}
