@import '@angular/material/theming';
@import 'standard-colour-codes';
/************************************** FONT DEFINITION **************************************/

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format('woff2');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Regular'), local('Roboto-Regular'), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Regular'), local('Roboto-Regular'), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Regular'), local('Roboto-Regular'), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Regular'), local('Roboto-Regular'), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Regular'), local('Roboto-Regular'), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Regular'), local('Roboto-Regular'), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Regular'), local('Roboto-Regular'), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format('woff2');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format('woff2');
	unicode-range: U+0370-03FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

// shakespeare gilroy fonts

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 400;
	src: url(../gilroy-fonts/Shakespeare-Regular.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 400;
	src: url(../gilroy-fonts/Shakespeare-Regular.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 500;
	src: url(../gilroy-fonts/Shakespeare-Medium.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 500;
	src: url(../gilroy-fonts/Shakespeare-Medium.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 600;
	src: url(../gilroy-fonts/Shakespeare-SemiBold.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 600;
	src: url(../gilroy-fonts/Shakespeare-SemiBold.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 700;
	src: url(../gilroy-fonts/Shakespeare-Bold.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 700;
	src: url(../gilroy-fonts/Shakespeare-Bold.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 800;
	src: url(../gilroy-fonts/Shakespeare-ExtraBold.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 800;
	src: url(../gilroy-fonts/Shakespeare-ExtraBold.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 900;
	src: url(../gilroy-fonts/Shakespeare-Black.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 900;
	src: url(../gilroy-fonts/Shakespeare-Black.woff) format('woff');
}

/************************************** body **************************************/

* {
	outline: none;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: 'Gilroy';
	font-style: medium;
	font-size: 1rem;
	line-height: 1.4;
	font-weight: 400;
	-webkit-text-size-adjust: 100%;
	color: $filed-dark-gray;
	overflow-x: hidden;
	overflow-y: hidden;
	text-align: left;
	min-height: 100%;
	background-color: $filed-lightest-gray;
}
.k-grid {
	font-family: 'Gilroy';
	font-style: medium;
}
.mat-card {
	font-family: Gilroy, 'medium';
}
.mat-form-field {
	font-family: Gilroy, 'medium';
}
.mat-radio-button {
	font-family: Gilroy, 'medium';
}
.mat-option {
	font-family: Gilroy, 'medium';
}
.mat-stepper-horizontal {
	font-family: Gilroy, 'medium';
}
.mat-checkbox {
	font-family: Gilroy, 'medium';
}
html {
	position: relative;
	min-height: 100%;
	background-color: $white;
}

/*h1-h6*/

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5vh;
	line-height: 1.2;
	margin-top: 0.5vh;
}

h1 {
	font-size: 2.5rem;
	font-weight: 500;
	letter-spacing: 1.54px;
	color: $hover-blue-two;
}

h2 {
	font-size: 2rem;
	font-weight: 500;
	letter-spacing: 1.4px;
	color: $darkest-gray;
}

h3 {
	font-size: 1.5rem;
	font-weight: 500;
	letter-spacing: 1.12px;
	color: $filed-blue;
}

h4 {
	font-size: 1.375rem;
	font-weight: 600;
	color: $darkest-gray;
	letter-spacing: 0.98px;
}

h5 {
	font-size: 1.125rem;
	font-weight: 500;
	color: $darkest-gray;
}

h6 {
	font-size: 1rem;
	color: $darkest-gray;
	font-weight: 400;
}

.subtitle-main {
	font-size: 1.5rem;
	font-weight: 500;
	color: $darkest-gray;
	letter-spacing: 0.24px;
}

.subtitle-secondary {
	font-size: 1.38rem;
	font-weight: 500;
	color: $darkest-gray;
	letter-spacing: 0.22px;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 0.25rem;
	line-height: 1.3;
	font-size: 1rem;
}

ol,
ul {
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 1.65vh;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

b,
strong {
	font-weight: 600;
}

th {
	font-weight: 500;
	color: $darkest-gray;
	letter-spacing: 0.15px;
	font-size: 14px;
}

label {
	font-weight: 500;
}

small {
	font-size: 12px;
}

/*Links*/

a:hover,
a:focus,
a {
	text-decoration: none;
	background-color: transparent;
	color: $filed-blue;
}

a.link {
	color: $filed-blue;
	text-decoration: none;

	&:hover,
	&:focus {
		cursor: pointer;
		text-decoration: none;
	}
}
