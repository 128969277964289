// ! DEPRECATED FILE - KEPT SO EXISTING CODE DOESN'T BREAK ! //
//Primary Colours
$filed-blue: #1a73e9;

//Primary Colours (Blue Shades)
$Dark-navy-blue: #02234d;
$hover-blue-two: #0064e2;
$File-blue-faded-deactivated: #91c2fe;
$Selected-state: #e6f2ff;
$Pale-Blue: #f4f9ff;

//Secondary Colours (& Feedback colors)
$dark-mint: #00aeb3;
$mint: #09d8de;
$Mint-faded: #d8f2f3;
$Burgundy: #60100b;
$Emphasized-Blush: #dc3445;
$Blush-faded: #fdebe7;
$Sandy: #7e5f17;
$yellow: #ffc12f;
$Yellow-faded: #fff8e7;

//Greyscale
$white: #ffffff;
$LightGrey02: #fafafa;
$filed-lightest-gray: #f2f2f2;
$filed-lighter-gray: #d9d9d9;
$filed-light-gray: #a9a9a9;
$filed-light-gray-03: #f8f8f8;
$filed-dark-gray: #484848;
$filed-new-gray: #bebebe;
$darkest-gray: #0b0b0b;
$black: #000;
//Main colors
$hover-blue-one: #003d8a;
$light-blue: #2fd9f8;
$filed-coral: #ff6e4e;
$filed-coral-red: #ff390d;
$hover-blue-three: #0170fc;
//creative builder -temporary added
$save-button: #ff6e4f;
$facebook-gradient-start: rgba(56, 204, 246, 1);
$facebook-gradient-end: rgba(0, 140, 201, 1);
$instagram-gradient-start: #cf3084;
$instagram-gradient-end: #6b50c5;
$creative-builder-blue: #048dcf;
$backdrop-gray: #f5f5f5;
$icon-gray: #777676;
$select-border: #2585fe91;
$select-background: #2585fe33;
$button-border-gray: #cecece;
$error: #ff0000;

$white: #ffffff;
$filed-background-gray: #f6f6f6;
$sidenav-bg-color: #484848;
$sidenav-active-bg-color: #5b5b5b;
$filed-blue-light: #e6f2ff;
$dark-grey-15-opacity: #d9d9d9;
$dark-grey-35-opacity: #a9a9a9;
$filed-grey: #484848;
$filed-gray-menu: #f0f0f0;
$new-sidenav-color: #fff;
