.flat-card-hover-shadow {
	border-radius: var(--border-radius-5);
	border: 1px solid var(--accent-blue-shade);
	padding: 12px 16px;
	cursor: pointer;
	transition: 0.2s all;

	&:hover {
		box-shadow: 0px 4px 4px 0px #0000001f;
	}

	&.selected {
		border: 1px solid var(--primary-color);
		box-shadow: 0px 4px 4px 0px #0000001f;
	}
}

.dark-shadow-card {
	@include dark-shadow-card-mixin();
}
